import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IProjectGroup } from '../Models';

interface IProjectsState {
    projectGroupsList: IProjectGroup[] | null,
}

const initialState: IProjectsState = {
    projectGroupsList: null,
}

export const projectGroupsSlice = createSlice({
    name: 'projectGroups',
    initialState,
    reducers: {
        setCurrentProjectGroups: (state, action: PayloadAction<IProjectGroup[]>) => {
            state.projectGroupsList = action.payload;
        }
    }
});

export const projectGroupsApi = createApi({
    reducerPath: 'projectGroups_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/projectgroup`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    tagTypes: ['GroupsList'],
    endpoints(builder) {
        return {
            fetchProjectGroups: builder.query<IProjectGroup[], Date[]>({
                query(dateRange) {
                    var startDate = dateRange[0].toDateString();
                    var endDate = dateRange[1].toDateString();
                    return `/GetProjectGroups?startDate=${startDate}&endDate=${endDate}`;
                },
                providesTags: ['GroupsList']
            }),
            postProjectGroup: builder.mutation({
                query: project => ({
                    url: `/PostProjectGroup`,
                    method: 'POST',
                    body: project
                }),
                invalidatesTags: ['GroupsList']
            }),
            putProjectGroup: builder.mutation({
                query: group => ({
                    url: `/PutProjectGroup?id=${group.id}`,
                    method: 'PUT',
                    body: group
                }),
                invalidatesTags: ['GroupsList']
            }),
            updateGroupsOrder: builder.mutation({
                query: newGroupsOrder => ({
                    url: `/UpdateGroupsOrder`,
                    method: 'POST',
                    body: newGroupsOrder
                }),
                invalidatesTags: ['GroupsList']
            }),
            addProjectToGroup: builder.mutation({
                query: args => ({
                    url: `/AddProjectToGroup?groupId=${args.groupId}&projectId=${args.projectId}`,
                    method: 'POST'
                }),
                invalidatesTags: ['GroupsList']
            }),
            removeProjectFromGroup: builder.mutation({
                query: args => ({
                    url: `/RemoveProjectFromGroup?projectId=${args.projectId}`,
                    method: 'POST'
                }),
                invalidatesTags: ['GroupsList']
            }),
            deleteProjectGroup: builder.mutation({
                query: projectGroupId => ({
                    url: `/DeleteProjectGroup?id=${projectGroupId}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['GroupsList']
            })
        }
    }
});

export const { useFetchProjectGroupsQuery,
    useLazyFetchProjectGroupsQuery,
    usePostProjectGroupMutation,
    usePutProjectGroupMutation,
    useUpdateGroupsOrderMutation,
    useAddProjectToGroupMutation,
    useDeleteProjectGroupMutation,
    useRemoveProjectFromGroupMutation } = projectGroupsApi;

export const { setCurrentProjectGroups } = projectGroupsSlice.actions;

export default projectGroupsSlice.reducer;