import { useEffect, useState } from "react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector, hideNotificationDocument } from "../../Redux"
import { isMobile } from 'react-device-detect';
import { b64toBlob } from "../../Utils/Utils"

export const GlobalNotificationDocument = () => {
    const dispatch = useAppDispatch();

    const showNotificationDocument = useAppSelector(s => s.administration.showNotificationDocument);
    const currentGlobalNotification = useAppSelector(s => s.administration.currentGlobalNotification);

    const [showPdf, setShowPdf] = useState<boolean>(false);

    useEffect(() => {
        if (showNotificationDocument) {
            openFile();
        }
    }, [showNotificationDocument])

    const openFile = () => {
        if (isMobile) {
            const fileURL = window.URL.createObjectURL(b64toBlob(currentGlobalNotification?.file as string, "application/pdf"));
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = currentGlobalNotification?.fileName as string;
            alink.click();
        }
        else {
            setShowPdf(true);
        }
    }

    const hideFile = () => {
        setShowPdf(false);
        dispatch(hideNotificationDocument());
    }

    return (<>
        {showPdf &&
            <div className="z-[112] absolute flex flex-col items-center justify-center w-screen h-screen top-0 left-0 backdrop-blur-sm py-8">
                <div className="flex justify-end bg-white h-6 w-full xl:w-[1200px]">
                    <span className="w-5 h-5 cursor-pointer">
                        <XMarkIcon onClick={hideFile} />
                    </span>
                </div>
                <embed src={`data:application/pdf;base64,${currentGlobalNotification?.file}`} className="flex relative w-full xl:w-[1200px] h-screen" />
            </div>
        }
    </>
    )
}