import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAdministration } from '../Models/IAdministration';

interface IAdministrationState {
    hasGlobalnotification: boolean,
    isError : boolean,
    isMaintainance : boolean,
    showMaintenancePanel: boolean,
    maintenanceEndDate?: Date,
    showNotificationDocument: boolean,
    currentGlobalNotification?: IAdministration
}

const initialState: IAdministrationState = {
    hasGlobalnotification: false,
    isError : false,
    isMaintainance: false,
    showMaintenancePanel: false,
    showNotificationDocument: false,
}

export const administrationSlice = createSlice({
    name: 'administration',
    initialState,
    reducers: {
        setErrorState: (state) => {
            state.isError = true;
        },
        setHasGlobalNotification: (state) => {
            state.hasGlobalnotification = true;
        },
        setIsMaintenance: (state, action: PayloadAction<boolean>) => {
            state.isMaintainance = action.payload;
        },
        setMaintenanceEndDate: (state, action: PayloadAction<Date>) => {
            state.maintenanceEndDate = action.payload;
        },
        switchShowMaintenancePanel: (state) => {
            state.showMaintenancePanel = !state.showMaintenancePanel;
        },
        showNotificationDocument: (state, action: PayloadAction<IAdministration>) => {
            if (action.payload) {
                state.currentGlobalNotification = action.payload;
                state.showNotificationDocument = true;
            }
        },
        hideNotificationDocument: (state) => {
            state.currentGlobalNotification = undefined;
            state.showNotificationDocument = false;
        }
    }
});

export const administrationApi = createApi({
    reducerPath: 'administration_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/administration`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchGlobalNotifications: builder.query<IAdministration[], number>({
                query(lcid) {
                    return `/GetGlobalNotifications?lcid=${lcid}`;
                }
            }),
            fetchYearEndProcedures: builder.query<IAdministration[], void>({
                query() {
                    return `/GetYearEndProcedures`;
                }
            }),
            getAdministration: builder.query<IAdministration, void>({
                query() {
                    return `/GetAdministration`;
                }
            }),
            getMaintenanceStatus: builder.query<{isMaintenance: boolean}, void>({
                query() {
                    return `/GetMaintenanceStatus`;
                }
            }),
            putAdministration: builder.mutation({
                query: administration => ({
                    url: `/PutAdministration?id=${administration.id}`,
                    method: 'PUT',
                    body: administration
                })
            }),
            uploadYearEndfile: builder.mutation<{}, FormData>({
                query: data => ({
                    url: `/UploadYearEndFile?id=${data.get('id')}`,
                    method: 'POST',
                    body: data,
                }),
            }),
            putStartAndEndMaintenanceDate: builder.mutation({
                query: params => ({
                    url: `/PutStartAndEndDateMaintenance?startDate=${params.startDate.toUTCString()}&endDate=${params.endDate.toUTCString()}`,
                    method: 'PUT',
                    body: params
                })
            })
        }
    }
});


export const { 
    useFetchGlobalNotificationsQuery, 
    useLazyFetchGlobalNotificationsQuery,
    useLazyFetchYearEndProceduresQuery,
    useGetAdministrationQuery,
    useLazyGetAdministrationQuery,
    useGetMaintenanceStatusQuery,
    useLazyGetMaintenanceStatusQuery,
    usePutAdministrationMutation,
    useUploadYearEndfileMutation,
    usePutStartAndEndMaintenanceDateMutation } = administrationApi;

export const { 
    setErrorState,
    setHasGlobalNotification,
    setIsMaintenance,
    setMaintenanceEndDate,
    switchShowMaintenancePanel,
    showNotificationDocument, 
    hideNotificationDocument } = administrationSlice.actions;

export default administrationSlice.reducer;