import { CheckIcon, ExclamationTriangleIcon, ArrowPathIcon } from '@heroicons/react/24/outline'

interface ITimeEntryStateProps {
    timeEntryState: number
}

export const TimeEntryStateIcon = (props: ITimeEntryStateProps) => {
    const { timeEntryState } = { ...props }

    return (<>
        {timeEntryState == 1 && <span className='mx-1' title='OK'><CheckIcon className="text-green-light stroke-[1] stroke-green-light h-4 w-4" /></span>}
        {timeEntryState == 0 && <span className='mx-1' title='Error'><ExclamationTriangleIcon className="text-red stroke-[1] stroke-red  h-4 w-4" /></span>}
        {timeEntryState > 1 && <span className='mx-1' title='Sync'><ArrowPathIcon className="text-sync stroke-[1] stroke-sync h-4 w-4" /></span>}
    </>
    );
}