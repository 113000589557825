import { IFeature } from "../../Models"

interface IChangelogFeatureProps {
    feature: IFeature,
    isNew?: boolean,
    isBugFix?: boolean,
    isDeprecated?: boolean
}

export const ChangelogFeature = (props: IChangelogFeatureProps) => {
    const { feature, isBugFix, isDeprecated, isNew } = { ...props }
    return (
        <div className="flex flex-row space-x-2 items-start py-1">
            <span>
                {isNew &&
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="stroke-green-light w-6 h-6 mt-[3px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                }
                {isBugFix &&
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 stroke-violet  mt-[3px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                }
                {isDeprecated &&
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 stroke-red mt-[3px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                }
            </span>
            <span className="whitespace-pre-line">{feature.text}</span>
        </div>
    )
}