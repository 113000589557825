import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ICountry } from '../Models';

export const countryApi = createApi({
    reducerPath: 'country_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/country`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchCountries: builder.query<ICountry[], void>({
                query() { return `/GetCountries`; },
            })
        }
    }
});

export const { useLazyFetchCountriesQuery, useFetchCountriesQuery } = countryApi;