import { useState, useEffect } from "react";
import { useAppSelector } from "../../Redux";
import { DateTime } from "luxon";
import { DatePicker } from "./DatePickerComponent";
import { DateRangeType } from "@fluentui/react-calendar-compat";
import { useOutsideClick } from "../../Utils/UseOutsideClick";

export const CalloutDatePicker = () => {

    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [locale, setLocale] = useState<string>('en');
    const [calendarButtonText, setCalendarButtonText] = useState<string>()

    const buttonContainerRef = useOutsideClick(() => {
        setShowCalendar(false);
    });

    useEffect(() => {
        if (dateRange.length > 0) {
            const firstDate = DateTime.fromJSDate(dateRange[0]).setLocale(locale);
            if (dateRangeType == DateRangeType.Month) {
                setCalendarButtonText(firstDate.toFormat("MMMM yyyy"));
            }
            else {
                const lastDate = DateTime.fromJSDate(dateRange[1]).setLocale(locale);
                setCalendarButtonText(`${firstDate.toFormat('d MMM')} - ${lastDate.toFormat('d MMM yyyy')}`)
            }
        }
    }, [dateRange, dateRangeType]);

    return (<>
        {
            dateRange.length > 0 &&
            (
                <div ref={buttonContainerRef} className='flex lg:hidden z-50'>
                    <div className="border-t-0 border-r-0 border-l-0 border-b px-3 border-b-primary text-sm select-none" onClick={() => setShowCalendar(!showCalendar)}>{calendarButtonText}</div>
                    <div className={`${showCalendar ? 'flex' : 'hidden'} fixed top-52 left-1/2 -translate-x-1/2 flex-grow px-5 rounded-md shadow-md bg-white z-100`}>
                        <div className="flex flex-col w-full">
                            <DatePicker />
                        </div>
                    </div>
                </div>
            )
        }
    </>)
}