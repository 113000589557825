import { DateTime } from 'luxon'
import { translate } from "react-i18nify";
import { ChangeEvent, useEffect, useState } from 'react';
import TimeEntryEditMode from './TimeEntryEditComponent';
import { ITimeEntry, IWorkPackage, TimeEntryState } from '../../Models';
import {
    useAppDispatch, useAppSelector, showNotificationWithTimeout, useDeleteTimeRecordMutation,
    useResendTimeRecordMutation, updateLoadingState, openTimeEntryCopyPanel, useRevertTimeRecordMutation, useLazyWaitForTimeRecordingStateUpdateQuery,
    addTimeEntryToDelete, removeTimeEntryToDelete,
} from '../../Redux';
import { transformMinutesToBookedTime, trimErrorMessage } from '../../Utils/Utils';
import { ConfirmDialog } from '../Info/ConfirmDialog';
import { TimeEntryStateIcon } from './TimeEntryStateIcon';
import { PencilIcon, TrashIcon, DocumentDuplicateIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { FailedTimeEntryActions } from './FailedTimeEntryActionsButton';
import { Delete32Light } from '@fluentui/react-icons'

interface ITimeEntryProps {
    isFailedState?: boolean,
    timeEntry: ITimeEntry,
    onUpdate?: Function,
    refreshTimeRecords: Function,
    updateWorkpackageCallback?: Function,
    disabledControls?: boolean
}

const TimeEntry = (props: ITimeEntryProps) => {
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const timeRecordsToBeDeleted = useAppSelector((state) => state.timeEntries.timeEntriesToBeDeleted);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [comment, setComment] = useState<string | undefined>('');
    const [timeRecord, setTimeRecord] = useState<ITimeEntry>(props.timeEntry);
    const [timeRecordState, setTimeRecordState] = useState<number>(props.timeEntry.state);
    const [booketTimeString, setBooketTimeString] = useState<string>('');
    const [isDeleteDialogHidden, setIsDeleteDialogHidden] = useState<boolean>(true);
    const [isAddHours, setIsAddHours] = useState<boolean>(true);

    const [deleteTimeEntry, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError }] = useDeleteTimeRecordMutation();
    const [resendTimeRecord, { isLoading: isResendLoading, isSuccess: isResendSuccess, isError: isResendError, error: resendError }] = useResendTimeRecordMutation();
    const [revertTimeRecord, { isLoading: isRevertLoading, isSuccess: isRevertSuccess, isError: isRevertError, error: revertError }] = useRevertTimeRecordMutation();
    const [waitForTimeRecordStateUpdate, { data: stateUpdateResult, isSuccess: isStateUpdateSuccess }] = useLazyWaitForTimeRecordingStateUpdateQuery();

    useEffect(() => {
        if (isDeleteLoading || isResendLoading || isRevertLoading) {
            dispatch(updateLoadingState(true));
        }
    }, [isDeleteLoading, isResendLoading, isRevertLoading])

    useEffect(() => {
        if (isDeleteSuccess) {
            displayNotification('App_Notification_TimeRecording_Delete_Success');
            props.refreshTimeRecords();
            props.updateWorkpackageCallback && props.updateWorkpackageCallback(timeRecord.estimatedLength, isAddHours);
        } else if (isDeleteError && deleteError) {
            displayNotification(deleteError.toString(), true);
        }

        dispatch(updateLoadingState(false));
    }, [isDeleteSuccess, isDeleteError, deleteError]);

    useEffect(() => {
        if (isResendSuccess) {
            displayNotification('App_Notification_TimeRecording_Update_Success');
        } else if (isResendError && resendError) {
            displayNotification(resendError.toString(), true);
        }

        dispatch(updateLoadingState(false));
    }, [isResendSuccess, isResendError, resendError]);

    useEffect(() => {
        if (isRevertSuccess) {
            displayNotification('App_Notification_TimeRecording_Update_Success');
        } else if (isRevertError && revertError) {
            displayNotification(revertError.toString(), true);
        }

        dispatch(updateLoadingState(false));
    }, [isRevertSuccess, isRevertError, revertError]);

    useEffect(() => {
        setBooketTimeString(transformMinutesToBookedTime(timeRecord.estimatedLength));
        setComment(timeRecord.comment);
        setTimeRecordState(timeRecord.state);

        if (timeRecord && timeRecord.id && timeRecord.state == TimeEntryState.Pending) {
            waitForTimeRecordStateUpdate({ id: timeRecord.id });
        }
    }, [timeRecord])

    useEffect(() => {
        if (isStateUpdateSuccess && stateUpdateResult) {
            setTimeRecordState(stateUpdateResult);
        }
    }, [stateUpdateResult, isStateUpdateSuccess])

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    const onEditClick = () => {
        if (!props.disabledControls) {
            setEditMode(true);
        }
    }

    const setViewMode = () => {
        setEditMode(false);
    }

    const updateTimeRecording = async (data: ITimeEntry) => {
        if (props.onUpdate) {
            setTimeRecord(data);
            await props.onUpdate(data);
            setEditMode(false);
            props.refreshTimeRecords();
        }
    }

    const onDeleteClick = () => {
        if (!props.disabledControls) {
            setIsDeleteDialogHidden(false);
        }
    }

    const onResyncClick = async () => {
        var workPackageCopy = { ...props.timeEntry.workingPackage } as IWorkPackage

        let timeEntry = { ...props.timeEntry };
        timeEntry.state = 2;
        timeEntry.userProfile = userProfile;
        timeEntry.workingPackage = {
            id: workPackageCopy.id,
            title: workPackageCopy.title,
            from: workPackageCopy.from,
            to: workPackageCopy.to,
            totalPT: workPackageCopy.totalPT,
            availablePT: workPackageCopy.availablePT,
            private: workPackageCopy.private,
            bookable: workPackageCopy.bookable
        };

        await resendTimeRecord(timeEntry);
        setTimeRecord(timeEntry);
    }

    const onRevertClick = async () => {
        await revertTimeRecord(props.timeEntry.xrmKey);
    }

    const confirmDeleteClicked = async () => {
        setIsAddHours(false);
        setIsDeleteDialogHidden(true);
        await deleteTimeEntry(props.timeEntry.id);
    }

    const onCopyClick = () => {
        if (timeRecordState == TimeEntryState.Ok && !props.disabledControls) {
            dispatch(openTimeEntryCopyPanel(timeRecord));
        }
    }

    function onSelectForDeleteClick(event: ChangeEvent<HTMLInputElement>): void {
        if (event.target.checked) {
            dispatch(addTimeEntryToDelete({ workPackageId: timeRecord.workingPackage_Id as number, timeEntryId: timeRecord.id as string }));
        } else {
            dispatch(removeTimeEntryToDelete({ workPackageId: timeRecord.workingPackage_Id as number, timeEntryId: timeRecord.id as string }));
        }
    }

    return (<div className={`${editMode && 'pb-5'}`}>
        {
            editMode
                ? <TimeEntryEditMode timeEntry={timeRecord} onClose={setViewMode} onSave={updateTimeRecording} refreshTimeRecords={props.refreshTimeRecords} updateWorkpackageCallback={props.updateWorkpackageCallback} />
                :
                (
                    <>
                        <div className="w-full h-16 lg:h-10 px-2 text-sm flex flex-row items-center space-x-1">
                            <div className="w-[5%]"><TimeEntryStateIcon timeEntryState={timeRecordState} /></div>
                            <div className="w-1/5 font-semibold">{DateTime.fromJSDate(new Date(timeRecord.on)).toFormat('ccc dd LLL yyyy')}</div>
                            <div className="w-1/5">{booketTimeString}</div>
                            <div className="w-2/5 truncate">{comment}</div>
                            {props.isFailedState ?
                                <FailedTimeEntryActions showRevert={timeRecord.xrmKey != null} editCallback={onEditClick} retryCallback={onResyncClick} deleteCallback={onDeleteClick} revertCallback={onRevertClick} />
                                :
                                <>
                                    <div className="w-[5%]"><PencilIcon className={`${!props.disabledControls ? 'cursor-pointer stroke-primary' : 'cursor-not-allowed stroke-gray-dark'} stroke-1  h-4 w-5`} onClick={onEditClick} /></div>
                                    <div className="w-[5%]">
                                        {timeRecordState == TimeEntryState.Faulted ?
                                            <>
                                                <ArrowPathIcon className='cursor-pointer stroke-1 stroke-primary h-4 w-5' onClick={onResyncClick} />
                                            </>
                                            : <DocumentDuplicateIcon className={`${timeRecordState != TimeEntryState.Ok || props.disabledControls ? 'cursor-not-allowed stroke-gray-dark' : 'cursor-pointer stroke-primary'} stroke-1  h-4 w-5`} onClick={onCopyClick} />}
                                    </div>
                                    <div className={`w-[5%] ${(timeRecordsToBeDeleted.workPackageId == timeRecord.workingPackage_Id || !timeRecordsToBeDeleted.workPackageId) && 'flex flex-row items-center space-x-3'}`}>
                                        <TrashIcon className={`${!props.disabledControls ? 'cursor-pointer stroke-primary' : 'cursor-not-allowed stroke-gray-dark'} cursor-pointer stroke-1 h-4 w-5`} onClick={onDeleteClick} />
                                        {
                                            (timeRecordsToBeDeleted.workPackageId == timeRecord.workingPackage_Id || !timeRecordsToBeDeleted.workPackageId) && <input
                                                id={`delete-activity-recording-${timeRecord.id}`}
                                                name={`delete-activity-recording-${timeRecord.id}`}
                                                type="checkbox"
                                                aria-describedby={`delete-activity-recording-${timeRecord.id}`}
                                                className={`${!props.disabledControls ? 'cursor-pointer stroke-primary' : 'cursor-not-allowed stroke-gray-dark'} hidden lg:block h-4 w-4 rounded-sm border-black text-primary focus:ring-primary accent-primary`}
                                                disabled={props.disabledControls}
                                                onChange={onSelectForDeleteClick}
                                            />
                                        }
                                    </div>
                                </>}
                        </div>
                        {
                            timeRecord.faultMessage &&
                            <div className="text-red w-full truncate px-2">
                                <span>{trimErrorMessage(timeRecord.faultMessage)}</span>
                            </div>
                        }
                    </>
                )
        }

        <ConfirmDialog isHidden={isDeleteDialogHidden}
            infoTitle='App_Info_Confirm_Delete_Title'
            infoText='App_Info_Confirm_Delete'
            icon={<Delete32Light />}
            onConfirmClick={confirmDeleteClicked}
            onDismissClick={() => setIsDeleteDialogHidden(true)} />
    </div>);
}

export default TimeEntry;