import { useAppSelector } from "../../Redux";

export const NotificationMessages = () => {
    const notifications = useAppSelector((state) => state.notification.notifications);

    return (<div className="flex items-center flex-col w-full space-y-1">
        {
            notifications.map((n, i) =>
                <div key={i} className={`w-full z-[1001] max-w-md overflow-hidden rounded-md shadow-md ${n.isError ? 'bg-[#fde7e9]' : 'bg-white'}`}>
                    <div className="p-3">
                        <div className="flex items-start justify-center">
                            <div className="flex-shrink-0">
                                {
                                    !n.isError ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 stroke-green-light">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 stroke-red">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                        </svg>

                                }
                            </div>
                            <div className="ml-3 w-0 flex-1 text-center">
                                <p className="text-sm text-black">{n.notificationMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    </div>);
}