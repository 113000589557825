import { useAppDispatch, useAppSelector, updateLoadingState, setCurrentProjects, useLazyFetchProjectsQuery, setHiddenProjectsExist } from '../../Redux'
import { IProjectGroup, IUserProject } from '../../Models';
import { useEffect, useState } from 'react';
import { ProjectGroupComponent } from '../ProjectGroups/ProjectGroupComponent';
import { ProjectRow } from '../Projects/ProjectRowComponent';
import NoProjectsInfoComponent from '../Projects/NoProjectsInfoComponent';
import { Divider } from '../Divider/DividerComponent';

export const ProjectDashboardComponent = () => {
    const dispatch = useAppDispatch();

    const [projects, setProjects] = useState<IUserProject[]>([]);
    const [ungrouppedProjects, setUngrouppedProjects] = useState<IUserProject[]>([]);
    const [groups, setGroups] = useState<IProjectGroup[]>([]);

    const dateRange = useAppSelector((state) => state.calendar.dateRange);
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const projectGroups = useAppSelector((state) => state.projectGroups.projectGroupsList);
    const currentProjects = useAppSelector((state) => state.projects.projectsList);
    const [fetchProjects, { data: projectsList, isLoading: fetchProjectsLoading, isSuccess: fetchProjectsSuccess, isError: fetchProjectsError }] = useLazyFetchProjectsQuery();

    useEffect(() => {
        if (fetchProjectsLoading) {
            dispatch(updateLoadingState(true));
        } else if (fetchProjectsSuccess) {
            dispatch(updateLoadingState(false));
            dispatch(setCurrentProjects(projectsList as IUserProject[]));
            dispatch(setHiddenProjectsExist());
        }
        else if (fetchProjectsError) {
            dispatch(updateLoadingState(false));
        }
    }, [fetchProjectsLoading, fetchProjectsSuccess, fetchProjectsError, projectsList]);

    useEffect(() => {
        if (dateRange.length > 0 && userProfile && projectGroups) {
            fetchProjects(dateRange);
            setGroups(projectGroups.filter(g => g.projectCount > 0));
        }
    }, [dateRange, userProfile, projectGroups])

    useEffect(() => {
        setProjects(currentProjects);
        setUngrouppedProjects(currentProjects.filter(p => p.projectGroup_Id == null));
    }, [currentProjects])

    return (<>
        <div className='flex flex-col w-full'>
            {
                projects && projects.length > 0 ?
                    (
                        [
                            groups && groups.length > 0 &&
                            groups.map((g, i) => <ProjectGroupComponent key={g.id} projectGroup={g} autoExpand={i == 0} />),

                            //show divider if there are projects in groups as ungrouped
                            groups && groups.length > 0 &&
                            ungrouppedProjects && ungrouppedProjects.length > 0 && <Divider key='ungpd' text='App_Label_Ungroupped_Projects' translate={true} />,

                            // // show ungroupped projects
                            ungrouppedProjects && ungrouppedProjects.length > 0 &&
                            <div className='mx-2 space-y-3 lg:mx-4'>
                                {ungrouppedProjects.map((p, i) => <ProjectRow key={p.id} project={p} isExpanded={!p.isHidden && projects.filter(p => !p.isHidden).length == 1} />)}
                            </div>

                        ]
                    )
                    : (
                        fetchProjectsSuccess && <NoProjectsInfoComponent />
                    )
            }
        </div>
    </>)
}