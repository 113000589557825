import { FormEvent, useEffect, useState } from 'react';
import { useLazyFetchYearEndProceduresQuery, usePutAdministrationMutation, useUploadYearEndfileMutation } from "../../Redux";
import { IAdministration } from '../../Models/IAdministration';
import { Translate } from 'react-i18nify';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { Button, Field, Label, Switch } from '@headlessui/react'

export const YearEndProcedureSettingComponent = () => {
    const [englishProcedure, setEnglishProcedure] = useState<IAdministration | undefined>();
    const [germanProcedure, setGermanProcedure] = useState<IAdministration | undefined>();
    const [yearEndInfoText, setYearEndInfoText] = useState<string | undefined>('');
    const [yearEndHyperlinkText, setYearEndHyperlinkText] = useState<string | undefined>('');
    const [yearEndHyperlinkUrl, setYearEndHyperlinkUrl] = useState<string | undefined>('');
    const [showHyperlinkProps, setShowHyperlinkProps] = useState<boolean>(false);
    const [isYearEndActive, setIsYearEndActive] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState<string | undefined>();
    const [isGermanSelected, setIsGermanSelected] = useState<boolean>(false);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const [putAdministration, { isSuccess: updateSuccess, isLoading: updateLoading }] = usePutAdministrationMutation();
    const [getYearEndProcedures, { data: yearEndProcedures, isSuccess, isLoading }] = useLazyFetchYearEndProceduresQuery();
    const [uploadFile, { isSuccess: uploadSuccess, isLoading: uploadLoading }] = useUploadYearEndfileMutation();

    useEffect(() => {
        getYearEndProcedures();
    }, [])

    useEffect(() => {
        if (isSuccess && yearEndProcedures && yearEndProcedures.length > 1) {
            let english = yearEndProcedures.filter(t => t.lcid == 1033)[0];
            setEnglishProcedure(english);
            setGermanProcedure(yearEndProcedures.filter(t => t.lcid == 1031)[0]);
            if (english) {
                setYearEndInfoText(english.notificationMessage);
                setShowHyperlinkProps(english.notificationMessage.indexOf('%hyperlink%') > -1);
                setYearEndHyperlinkText(english.hyperlinkText);
                setYearEndHyperlinkUrl(english.hyperlinkUrl);
                setIsYearEndActive(english.isActive);
                setFileName(english.fileName);
            }
        }
    }, [yearEndProcedures, isSuccess])

    useEffect(() => {
        if (updateLoading) {
            setShowOverlay(true);
        } else if (updateSuccess) {
            setShowOverlay(false);
        }
    }, [updateSuccess, updateLoading])

    const onInfoTextChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newValue = e.currentTarget.value;
        setYearEndInfoText(newValue);
        if (isGermanSelected) {
            setGermanProcedure({ ...germanProcedure as IAdministration, notificationMessage: newValue });
        }
        else {
            setEnglishProcedure({ ...englishProcedure as IAdministration, notificationMessage: newValue });
        }

        let containsHypelinkText = newValue && newValue.indexOf('%hyperlink%') > -1;
        if (containsHypelinkText != showHyperlinkProps) {
            setShowHyperlinkProps(containsHypelinkText as boolean);
        }
    };

    const onHyperlinkTextChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newValue = e.currentTarget.value;
        setYearEndHyperlinkText(newValue);
        if (isGermanSelected) {
            setGermanProcedure({ ...germanProcedure as IAdministration, hyperlinkText: newValue });
        }
        else {
            setEnglishProcedure({ ...englishProcedure as IAdministration, hyperlinkText: newValue });
        }
    };

    const onHyperlinkUrlChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newValue = e.currentTarget.value;
        setYearEndHyperlinkUrl(newValue);
        if (isGermanSelected) {
            setGermanProcedure({ ...germanProcedure as IAdministration, hyperlinkUrl: newValue });
        }
        else {
            setEnglishProcedure({ ...englishProcedure as IAdministration, hyperlinkUrl: newValue });
        }
    };

    const onIsActiveClick = () => {
        if (isGermanSelected) {
            setGermanProcedure({ ...germanProcedure as IAdministration, isActive: !isYearEndActive });
        }
        else {
            setEnglishProcedure({ ...englishProcedure as IAdministration, isActive: !isYearEndActive });
        }
        setIsYearEndActive(!isYearEndActive);
    }

    const hendlefileUpload = (e: FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            setFile(e.currentTarget.files[0])
            setFileName(e.currentTarget.files[0].name)
        }
    }

    const switchLanguage = () => {
        if (isGermanSelected) {
            if (englishProcedure) {
                setYearEndInfoText(englishProcedure.notificationMessage);
                setShowHyperlinkProps(englishProcedure.notificationMessage.indexOf('%hyperlink%') > -1);
                setYearEndHyperlinkText(englishProcedure.hyperlinkText);
                setYearEndHyperlinkUrl(englishProcedure.hyperlinkUrl);
                setIsYearEndActive(englishProcedure.isActive);
                setFileName(englishProcedure.fileName);
            }
        } else {
            if (germanProcedure) {
                setYearEndInfoText(germanProcedure.notificationMessage);
                setShowHyperlinkProps(germanProcedure.notificationMessage.indexOf('%hyperlink%') > -1);
                setYearEndHyperlinkText(germanProcedure.hyperlinkText);
                setYearEndHyperlinkUrl(germanProcedure.hyperlinkUrl);
                setIsYearEndActive(germanProcedure.isActive);
                setFileName(germanProcedure.fileName);
            }
        }
        setIsGermanSelected(!isGermanSelected);
    }

    const onSaveYearEndProcedure = () => {
        let id = -1;
        if (!isGermanSelected) {
            putAdministration(englishProcedure);
            id = englishProcedure?.id as number;
        } else {
            putAdministration(germanProcedure);
            id = germanProcedure?.id as number;
        }

        if (file && fileName && id > -1) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id', `${id}`);
            uploadFile(formData);
        }
    }

    return (
        <div className="relative flex flex-col space-y-2 border border-primary rounded-sm p-2 shadow-md">
            {showOverlay && <div className='w-full h-full absolute top-0 left-0 bg-gray/30 z-50'></div>}
            <div className="absolute -top-3 left-2 inline-block bg-white px-1 text-base font-medium text-primary">
                <span className='drop-shadow-md'>Year end procedure settings</span>
            </div>
            <div className="absolute top-1 right-2 inline-block bg-white px-1 text-base font-medium text-primary">
                <Field as="div" className="flex justify-center items-center">
                    <Switch
                        checked={isGermanSelected}
                        onChange={switchLanguage}
                        className={`${isGermanSelected ? 'bg-primary' : 'bg-black/30'} relative inline-flex items-center h-3 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
                        <span
                            aria-hidden="true"
                            className={`${isGermanSelected ? 'translate-x-4' : '-translate-x-1'} pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white border border-black shadow ring-0 transition duration-200 ease-in-out`} />
                    </Switch>
                    <Label as="span" className="ml-3 text-sm">
                        <span className="font-medium text-gray-900">{isGermanSelected ? 'DE' : 'EN'}</span>
                    </Label>
                </Field>
            </div>
            <label className="text-sm">Message text</label>
            <div className="w-full flex shadow-sm ring-1 ring-inset ring-primary focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-alternate">
                <textarea
                    rows={3}
                    className="resize-none block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none"
                    value={yearEndInfoText}
                    onChange={onInfoTextChange} />
            </div>
            <p className="text-sm text-primary-dark" id="email-description">
                To create a personalized hyperlink, type %hyperlink% and the placeholder will automatically be replaced
            </p>
            {showHyperlinkProps &&
                <>
                    <label className="text-sm">Message link</label>
                    <div className="w-full flex shadow-sm ring-1 ring-inset ring-primary focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-alternate">
                        <span className="flex select-none font-semibold items-center pl-3 text-black/80 sm:text-sm">Link text:</span>
                        <input
                            type="text"
                            name="company-website"
                            id="company-website"
                            value={yearEndHyperlinkText}
                            onChange={onHyperlinkTextChange}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none"
                            placeholder="Example"
                        />
                    </div>
                    <div className="w-full flex shadow-sm ring-1 ring-inset ring-primary focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-alternate">
                        <span className="flex select-none font-semibold items-center pl-3 text-black/80 sm:text-sm">https://</span>
                        <input
                            type="text"
                            name="company-website"
                            id="company-website"
                            value={yearEndHyperlinkUrl}
                            onChange={onHyperlinkUrlChange}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none"
                            placeholder="www.example.com"
                        />
                    </div>
                </>
            }
            <div className="flex flex-row justify-between w-full text-sm text-black">
                <Field as="div" className="flex justify-center items-center">
                    <Label as="span" className="text-sm mr-3">
                        <span className="font-medium text-gray-900">Is Year End Message Active</span>
                    </Label>
                    <Switch
                        checked={isYearEndActive}
                        onChange={onIsActiveClick}
                        className={`${isYearEndActive ? 'bg-primary' : 'bg-black/30'} relative inline-flex items-center h-3 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
                        <span aria-hidden="true" className={`${isYearEndActive ? 'translate-x-4' : '-translate-x-1'} pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white border border-black shadow ring-0 transition duration-200 ease-in-out`} />
                    </Switch>
                </Field>
            </div>
            <div className="flex flex-col space-y-2 w-full">
                <label className="block text-sm font-medium leading-6 text-black sm:pt-1.5">File</label>
                <div className="w-full flex justify-center rounded-lg border border-dashed border-gray-dark px-6 py-6">
                    <div className="flex justify-center text-sm leading-6">
                        <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-primary
                                                                focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-alternate focus-within:ring-offset-2 
                                                                hover:text-primary-alternate" >
                            <ArrowUpOnSquareIcon className="mx-auto h-12 w-12 text-black/70" aria-hidden="true" />
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={hendlefileUpload} />
                        </label>
                    </div>
                </div>
                <div>
                    <p className='text-base font-semibold'>{fileName}</p>
                </div>
            </div>
            <div className="flex flex-row justify-center mt-2">
                <Button className="bg-primary flex flex-row items-center justify-center space-x-2 text-base text-tertiary cursor-pointer
                                    rounded py-2 px-4 data-[hover]:bg-primary/70 data-[active]:bg-primary/70" onClick={onSaveYearEndProcedure}>
                    <Translate value='App_Button_Save' />
                </Button>
            </div>
        </div>
    )
}