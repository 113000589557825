import { Translate } from "react-i18nify"
import { useAppDispatch, useAppSelector, hideAddActivityTemplateDialog } from "../../Redux";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { SpinButton, SpinButtonChangeEvent, SpinButtonOnChangeData } from "@fluentui/react-spinbutton";
import { IActivityTemplate } from "../../Models";

export const ActivityTemplateModalComponent = () => {
    const dispatch = useAppDispatch();
    const modalProps = useAppSelector((state) => state.activityTemplates.addActivityTemplateModalProps);

    const [title, setTitle] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [order, setOrder] = useState<number>(100);
    const [hours, setHours] = useState<number | undefined>(0);
    const [minutes, setMinutes] = useState<number | undefined>(0);

    useEffect(() => {
        if (!modalProps.isHidden) {
            if (modalProps.editableActivityTemplate) {
                setTitle(modalProps.editableActivityTemplate.title);
                setDescription(modalProps.editableActivityTemplate.description);
                setOrder(modalProps.editableActivityTemplate.order);
                setHours(Math.floor(modalProps.editableActivityTemplate.defaultBookedTime / 60));
                setMinutes(modalProps.editableActivityTemplate.defaultBookedTime % 60);
            } else {
                setTitle('');
                setDescription('');
                setHours(0);
                setMinutes(0);
            }
        }
    }, [modalProps])

    const dismisDialog = () => {
        dispatch(hideAddActivityTemplateDialog());
    }

    const onConfirmClick = () => {
        const newActivityTemplate: IActivityTemplate = {
            title: title as string,
            description: description,
            order: order,
            defaultBookedTime: (Number(hours) * 60) + Number(minutes)
        }

        modalProps.confirmCallback && modalProps.confirmCallback(newActivityTemplate);
    }

    const onTitleChange = (e: FormEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value);
    }

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    }

    const onHoursChange = (event: SpinButtonChangeEvent, data: SpinButtonOnChangeData): void => {
        if(data && data.value){
            setHours(data.value);
        }
    };

    const onMinutesChange = (event: SpinButtonChangeEvent, data: SpinButtonOnChangeData): void => {
        if(data && data.value){
            var roundedNumber = (Math.round(data.value / 15) * 15);

            setMinutes(roundedNumber <= 45 ? roundedNumber : 45);
        }
    };

    return (<>
        {
            !modalProps.isHidden &&
            <div className="relative z-50" aria-labelledby="delete-confirm-modal" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-neutral bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-[1011] overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mb-9 lg:mb-0 sm:my-8 w-full sm:max-w-2xl">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start flex-col">
                                    <div className="w-full">
                                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-black"><Translate value='App_Forms_Activity_Template_Name' /></label>
                                        <div className="mt-1">
                                            <input type="text" id="title" value={title} onChange={onTitleChange}
                                                className="block w-full rounded-sm border-0 p-2 text-black shadow-sm ring-1 ring-inset ring-gray-dark placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none sm:text-sm sm:leading-6" />
                                        </div>
                                        <p className="mt-1 text-sm text-gray-dark" id="title-description">
                                            <Translate value="App_Form_Activity_Template_Name_Info" />
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-black"><Translate value='App_Forms_Activity_Template_Description' /></label>
                                        <div className="mt-1">
                                            <textarea rows={2} name="comment" id="comment" value={description} onChange={onDescriptionChange}
                                                className="block w-full resize-none rounded-sm border-0 p-2 text-black shadow-sm ring-1 ring-inset ring-gray-dark placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none sm:text-sm sm:leading-6" />
                                        </div>
                                        <p className="mt-1 text-sm text-gray-dark" id="comment-description">
                                            <Translate value="App_Form_Activity_Template_Description_Info" />
                                        </p>
                                    </div>
                                    <div className="w-full flex flex-row space-x-1">
                                        <div className='w-1/2'>
                                            <label htmlFor="hours" className="block text-sm font-medium leading-6 text-black"><Translate value="App_Label_Hours" /></label>
                                            <div className="mt-1">
                                                <SpinButton id="hours" value={hours} min={0} max={400} step={1} onChange={onHoursChange} className="bg-white" />
                                            </div>
                                        </div>
                                        <div className='w-1/2'>
                                            <label htmlFor="minutes" className="block text-sm font-medium leading-6 text-black"><Translate value="App_Label_Minutes" /></label>
                                            <div className="mt-1">
                                                <SpinButton id="minutes" value={minutes} min={0} max={45} step={15} onChange={onMinutesChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="w-full mt-1 text-sm text-gray-dark" id="titl-description">
                                        <Translate value="App_Form_Activity_Template_Time_Info" />
                                    </p>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button" className="inline-flex w-full justify-center rounded-sm bg-primary px-3 py-2 text-sm font-semibold text-tertiary shadow-sm hover:bg-primary/70 sm:ml-3 sm:w-auto" onClick={onConfirmClick}><Translate value='App_Button_Save' /></button>
                                <button type="button" className="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-primary/50 hover:bg-gray/50 sm:mt-0 sm:w-auto" onClick={dismisDialog}><Translate value='App_Button_Cancel' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
}