import { FormEvent, KeyboardEvent, useState } from "react";
import { ChevronUpIcon, ChevronDownIcon  } from "@heroicons/react/24/outline";

interface INumberInput {
    value: number | undefined,
    min?: number,
    max?: number,
    step?: number,
    disabled?: boolean,
    onChange: Function
}

export const NumberInput = (props: INumberInput) => {
    const { value, min, max, step, disabled, onChange } = { ...props };

    const [currentValue, setCurrentValue] = useState<number>(Number(value));
    const [ignoreNumberChange, setIgnoreNumberChange] = useState<boolean>(false);
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (disabled)
            return;

        let newValue = 0;
        if (event.code === "ArrowUp") {
            newValue = currentValue + (step ?? 1);
            if (max != null && max != undefined && newValue <= max) {
                changeValueWithIgnore(newValue);
            }
        }

        if (event.code === "ArrowDown") {
            newValue = currentValue - (step ?? 1);
            if (min != null && min != undefined && newValue >= min) {
                changeValueWithIgnore(newValue);
            }
        }

        if (!Number.isNaN(Number(event.key))) {
            if (currentValue === 0) {
                newValue = Number(event.key)
            } else {
                newValue = Number(currentValue + event.key);
            }
            if ((max != null && max != undefined && newValue <= max) && (min != null && min != undefined && newValue >= min)) {
                changeValueWithIgnore(newValue);
            }
        }
    }

    const onNumberChange = (e: FormEvent<HTMLInputElement>) => {
        if (!ignoreNumberChange) {
            let newValue = e.currentTarget.value;
            if (!Number.isNaN(Number(newValue))) {
                const numberValue = Number(newValue)
                if ((max != null && max != undefined && numberValue <= max) && (min != null && min != undefined && numberValue >= min)) {
                    setCurrentValue(numberValue);
                    onChange(numberValue);
                }
            }
        }

        setIgnoreNumberChange(false);
    };

    const stepUp = () => {
        let newValue = currentValue + (step ?? 1);
        if (max && newValue > max) {
            return;
        }

        changeValueWithIgnore(newValue);
    }

    const stepDown = () => {
        let newValue = currentValue - (step ?? 1);
        if (min && newValue < min) {
            return;
        }

        changeValueWithIgnore(newValue);
    }

    function changeValueWithIgnore(newValue: number) {
        setCurrentValue(newValue);
        onChange(newValue);
        setIgnoreNumberChange(true);
    }

    return (<div className={`relative flex flex-row flex-1 h-8 max-w-full p-2 rounded-md justify-between items-center ring-1 ring-inset ring-gray-dark focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary`}>
        <input type="number"
            disabled={disabled}
            onKeyDown={handleKeyPress}
            onChange={onNumberChange}
            value={currentValue}
            className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 focus:outline-none`}></input>
        <span className="flex flex-col text-xs">
            <ChevronUpIcon className="w-4 h-4 cursor-pointer px-1 select-none" onClick={stepUp} />
            <ChevronDownIcon className="w-4 h-4 cursor-pointer px-1 select-none" onClick={stepDown} />
        </span>
    </div>)
}