import { Translate } from "react-i18nify"

interface ICalendarFormatButton {
    buttonText: string,
    calendarTypeValue: number,
    isActive: boolean,
    callbak: Function
}

export const CalendarFormatButton = (props: ICalendarFormatButton) => {

    const onClick = () => {
        props.callbak(props.calendarTypeValue);
    }

    return (<>
        <div className={`hidden lg:flex text-sm justify-start p-[2px] my-2 items-center text-primary cursor-pointer 
                        ${props.isActive ? 'bg-gradient-to-r from-secondary via-lightning to-coral font-semibold' : 'bg-transparent'}`} onClick={onClick}>
            <div className="bg-neutral p-1">
                <span><Translate value={props.buttonText} /></span>
            </div>
        </div>
    </>)
}