import { useState } from "react";
import { useAppSelector } from "../../Redux";
import { useOutsideClick } from "../../Utils/UseOutsideClick";
import { Home28Regular, QuestionCircle28Regular, ArrowDownload28Regular, AppsListRegular } from '@fluentui/react-icons';
import { NavigationButton } from "../Buttons/NavigationButton";
import { SignOutButton } from "../Buttons/SignOutButton";
import { ShowHiddenProjectsButton } from "../Buttons/ShowHiddenProjectsButton";
import { ChangelogNavigationButton } from "../Changelog/ChangelogNavigationButton";
import { UserDetailsPersona } from "../UserDetails/UserDetailsComponent";

export const Footer = () => {
  const userProfile = useAppSelector((state) => state.userProfile.userProfile);
  const hiddenProjectsExist = useAppSelector((state) => state.projects.hiddenProjectsExist);

  const [showProfileOptions, setShowProfileOptions] = useState<boolean>(false);

  const profileCalloutRef = useOutsideClick(() => {
    setShowProfileOptions(false);
  });

  return (<>
    <div className="flex flex-row w-full h-12 bg-white lg:hidden fixed bottom-0 right-0 justify-around items-center pt-1 z-50">
      <NavigationButton buttonText='App_Button_Activity_Templates' icon={<AppsListRegular className='stroke-primary w-5 h-5 stroke-0' />} navigationPath='/activityTemplates' small />
      <NavigationButton buttonText='App_Button_Download_Recording' icon={<ArrowDownload28Regular className='stroke-primary w-5 h-5 stroke-0' />} navigationPath='/download' small />
      <NavigationButton buttonText='Home' icon={<Home28Regular className='stroke-primary w-5 h-5 stroke-0' />}  navigationPath='/dashboard' small />
      <ChangelogNavigationButton small />
      <div ref={profileCalloutRef}>
        <div onClick={() => setShowProfileOptions(!showProfileOptions)} className='flex justify-center items-center'>
          <UserDetailsPersona />
        </div>
        <div className={`${showProfileOptions ? 'flex' : 'hidden'} fixed bottom-12 flex-grow px-3 rounded-md shadow-md bg-white right-4 animate-fade-in`}>          
          <div className="flex flex-col w-full">
            {hiddenProjectsExist && <ShowHiddenProjectsButton />}
            <NavigationButton
              openInNewWindow
              buttonText='App_Button_Help'
              icon={<QuestionCircle28Regular className='stroke-primary stroke-0 w-5 h-5' />}
              callback={() => setShowProfileOptions(false)}
              navigationPath={userProfile?.preferredLanguage === 1031 ? process.env.REACT_APP_HELP_URL_DE : process.env.REACT_APP_HELP_URL_EN} />
            <SignOutButton />
          </div>
        </div>
      </div>
    </div>
  </>)
}