import { Translate } from "react-i18nify"
import { switchShowMaintenancePanel, useAppDispatch } from "../../Redux"
import { CalendarSettings28Regular } from "@fluentui/react-icons"
import { NavigationButton } from "./NavigationButton"

interface IMaintenancePanelButton {
    small?: boolean
}

export const MaintenancePanelButton = (props: IMaintenancePanelButton) => {
    const { small } = { ...props };

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(switchShowMaintenancePanel());
    }

    return (<>
        <NavigationButton buttonText='App_Button_Maintenance' icon={<CalendarSettings28Regular className="stroke-primary stroke-0 w-5 h-5" />} isActionButton callback={onClick} small={small} />
    </>)
}