import { useAppSelector } from '../../Redux'
import { ProjectGroupItem } from './ProjectGroupItemComponent';

export const DroppableProjectGroupsListComponent = () => {
    const projectGroups = useAppSelector((state) => state.projectGroups.projectGroupsList);

    return (<>
        <div className='flex flex-col w-full space-y-1' id='projects-list'>
            {
                projectGroups && projectGroups.map((p, i) => <ProjectGroupItem key={p.id} projectGroup={p} isDroppable/>)
            }
        </div>
    </>)
}