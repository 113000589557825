import { DateTime } from "luxon";
import { ITimeEntry } from "../Models";

export const onFormatDate = (date?: Date): string => {
    return !date ? '' : DateTime.fromISO(date.toISOString()).toFormat('ccc dd LLL yyyy');
};

export const addDateTimeOffset = (date: Date): Date => {
    const offset = date.getTimezoneOffset();
    const dateWithOffset = date.setMinutes(date.getMinutes() - offset);

    return new Date(dateWithOffset);
}

export const downloadFile = (downloadData: any, blobType: string = '') => {
    var blobFile = window.atob(downloadData.fileContents);
    const byteNumbers = new Array(blobFile.length);
    for (let i = 0; i < blobFile.length; i++) {
        byteNumbers[i] = blobFile.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: blobType });

    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = downloadData.fileDownloadName;
    a.click();
}

export const transformMinutesToBookedTime = (bookedMinutes: number) => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    if (bookedMinutes > 24 * 60) {
        days = Math.floor(bookedMinutes / 60 / 24);
    }

    const timeLeft = bookedMinutes - (days * 24 * 60);
    hours = Math.floor(timeLeft / 60);
    minutes = timeLeft % 60;

    const daysString = days > 0 ? `${days}:` : '';
    const hoursString = hours > 9 ? `${hours}:` : `0${hours}:`;
    const minutesString = minutes > 0 ? `${minutes}` : '00';

    return `${daysString}${hoursString}${minutesString}`;
}

export const trimErrorMessage = (errorMessage: string): string => {
    let indexofStack = errorMessage.indexOf('Stack Trace');
    if (indexofStack > -1) {
        errorMessage = errorMessage.substring(0, indexofStack);
    }

    let exceptionText = 'Exception Message:';
    let indexOfException = errorMessage.indexOf(exceptionText);
    if (indexOfException > -1) {
        errorMessage = errorMessage.substring(indexOfException, errorMessage.length);
    }

    return errorMessage;
}

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export const groupBy = (list: any[] | undefined, keyGetter: Function): Map<string, ITimeEntry> => {
    const map = new Map();
    list?.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}

// For maintenance page countdown
const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

export const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
}

export const mapNumber = (number: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
    return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}