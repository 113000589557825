import { useEffect, useState } from "react";
import { IAdministration } from "../../Models/IAdministration";
import { PaperClipIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { setHasGlobalNotification, useAppDispatch, useAppSelector, useLazyFetchGlobalNotificationsQuery, showNotificationDocument } from "../../Redux"

export const GlobalNotifications = () => {
    const userProfile = useAppSelector(s => s.userProfile.userProfile);
    const dispatch = useAppDispatch();

    const [notification, setNotification] = useState<IAdministration | undefined>();
    const [isUrlNotification, setIsUrlNotification] = useState<boolean>(false);
    const [urlNotificationParts, setUrlNotificationParts] = useState<string[]>([]);
    const [fetchGlobalNotifications, { data: globalNotifications, isSuccess }] = useLazyFetchGlobalNotificationsQuery();

    useEffect(() => {
        if (userProfile && userProfile.preferredLanguage && userProfile.preferredLanguage > 0) {
            fetchGlobalNotifications(userProfile.preferredLanguage);
        }
    }, [userProfile, userProfile?.preferredLanguage])

    useEffect(() => {
        if (isSuccess && globalNotifications) {
            let globalNotification = globalNotifications[0];
            setNotification(globalNotification);
            const hyperlinkIndex = globalNotification.notificationMessage.indexOf('%hyperlink%')
            if (hyperlinkIndex > -1) {
                setUrlNotificationParts([
                    globalNotification.notificationMessage.substring(0, hyperlinkIndex - 1),
                    globalNotification.notificationMessage.substring(hyperlinkIndex + '%hyperlink%'.length + 1, globalNotification.notificationMessage.length - 1)
                ]);
                setIsUrlNotification(true);
            } else {
                setIsUrlNotification(false);
            }
            dispatch(setHasGlobalNotification());
        }
    }, [globalNotifications, isSuccess])

    const openFile = () => {
        dispatch(showNotificationDocument(notification as IAdministration));
    }

    return (<>
        {notification && 
            <div className="w-full bg-tertiary/80 bg-gradient-to-r from-secondary/50 to-coral/50 text-primary text-center flex items-center justify-center mx-0 p-2 text-xs lg:text-base">
                <InformationCircleIcon className="mr-2 w-6 h-6" />
                {isUrlNotification ?
                    <>
                    {urlNotificationParts[0]}&nbsp;<a className="underline" href={`https://${notification.hyperlinkUrl}`} target="_blank">{notification.hyperlinkText}</a>{urlNotificationParts[1]}
                    </>
                    : <>
                    {notification.notificationMessage}
                    </>
                }
                {notification.fileName && <PaperClipIcon className="ml-2 cursor-pointer h-6 w-6" onClick={openFile} />}
            </div>
        }
    </>
    )
}