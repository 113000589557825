import { useAppDispatch, useAppSelector, showAddActivityTemplateDialog, usePostActivityTemplateMutation, updateLoadingState, hideAddActivityTemplateDialog, showNotificationWithTimeout, setActivityTemplates } from "../../Redux";
import { IActivityTemplate, IErrorResponse } from "../../Models";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-i18nify";

interface IActivityTemplatesSelectComponent {
    selectedActivityTemplateId: string,
    onChange: Function
}

export const ActivityTemplatesSelectComponent = (props: IActivityTemplatesSelectComponent) => {
    const { onChange, selectedActivityTemplateId } = { ...props }

    const dispatch = useAppDispatch();
    var userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const activityTemplates: IActivityTemplate[] = useAppSelector((state) => state.activityTemplates.activityTemplatesList);
    const [selected, setSelected] = useState<IActivityTemplate | undefined>(activityTemplates.find(s => s.id == Number(selectedActivityTemplateId)));
    const [newActivityTemplate, setNewActivityTemplate] = useState<IActivityTemplate>();

    const [postActivityTemplate, { data: postResult, isLoading: isPostLoading, isSuccess: isPostSuccess, isError: isPostError, error: postError }] = usePostActivityTemplateMutation();

    const onSelectChange = (value: IActivityTemplate | string) => {
        if (value == 'add') {
            dispatch(showAddActivityTemplateDialog({ confirm: confirmAdd }))
        }
        else {
            setSelected(value as IActivityTemplate);
            onChange(value as IActivityTemplate);
        }
    }

    useEffect(() => {
        if (selectedActivityTemplateId == '') {
            setSelected(undefined);
        }
    }, [selectedActivityTemplateId]);

    useEffect(() => {
        if (isPostLoading) {
            dispatch(updateLoadingState(true));
        }
    }, [isPostLoading]);

    useEffect(() => {
        if (isPostSuccess) {
            setSelected(newActivityTemplate);
            onChange(newActivityTemplate);
            dispatch(hideAddActivityTemplateDialog());
            dispatch(setActivityTemplates([...activityTemplates, postResult]))
            displayNotification('App_Notification_Skill_Create_Success');
        }

        if (isPostError && postError) {
            const parsedError: IErrorResponse = { ...postError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPostSuccess, isPostError, postError]);

    const confirmAdd = (confirmResponse: IActivityTemplate) => {
        confirmResponse.userProfile = userProfile;
        confirmResponse.userProfile_Id = userProfile?.id;

        postActivityTemplate(confirmResponse);
        setNewActivityTemplate(confirmResponse);
    }

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    return (<>
        <Listbox value={selected} onChange={onSelectChange}>
            <div className="relative w-full">
                <Listbox.Button className={`relative w-full cursor-default rounded-sm h-8 bg-white pl-3 pr-10 text-left text-black ring-1 ring-inset ring-gray-dark focus:outline-none focus:ring-2 
                                    ${selected ? '' : 'pt-1'} 
                                    focus:ring-primary sm:text-sm sm:leading-6`}>
                    <span className="block truncate">{selected?.title}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon className="h-5 w-5 text-black" aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0">
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {activityTemplates.map((b) => (
                            <Listbox.Option key={b.id} value={b} className={({ active }) => `relative cursor-default select-none py-2 pl-3 pr-4 ${active ? 'bg-primary text-tertiary' : 'text-black'}`}>
                                {({ selected }) => (<><span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{b.title}</span></>)}
                            </Listbox.Option>
                        ))}
                        <Listbox.Option key='add' value='add' className='relative cursor-pinter select-none pl-3 pr-4 py-2'>
                            <div className="flex lg:text-sm items-center space-x-2 text-primary cursor-pointer">
                                <PlusIcon className='w-4 h-4' />
                                <span><Translate value='App_Button_Add_New' /></span>
                            </div>
                        </Listbox.Option>
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    </>)
}