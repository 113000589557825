import { useMsal } from "@azure/msal-react";
import { Routes, Route } from "react-router";
import { UnexpectedError } from "../Error/UnexpectedErrorComponent";
import { NoUserProfile } from "../NoUserProfile/NoUserProfileComponent";
import { ChangelogPage } from '../Changelog/ChangelogComponent';
import { Dashboard } from "../Dashboard/DashboardComponent";
import { Maintenance } from "../Maintenance/MaintenanceComponent";
import { ActivityTemplatesContainer } from "../ActivityTemplates/ActivityTemplatesComponent";
import { DownloadTimeEntries } from "../DownloadTimeEntries/DownloadTimeEntriesComponent";

export const Pages = () => {
    const { accounts } = useMsal();

    return (
        <>
            <Routes>
                <Route path='/dashboard' element={<>
                    <div className='w-full z-10'>
                        <Dashboard />
                    </div>
                </>
                } />

                <Route path='/activityTemplates' element={<>
                    <div className='w-full mb-2'>
                        <ActivityTemplatesContainer />
                    </div>
                </>} />

                <Route path='/download' element={<>
                    <div className='w-full'>
                        <DownloadTimeEntries />
                    </div>
                </>} />

                <Route path='/nouserprofile' element={<>
                    <div className='w-full'>
                        <NoUserProfile userLogin={accounts[0]?.username} />
                    </div>
                </>} />

                <Route path='/changelog' element={<>
                    <div className='w-full'>
                        <ChangelogPage />
                    </div>
                </>} />

                <Route path='/maintenance' element={<>
                    <div className='w-full h-screen'>
                        <Maintenance />
                    </div>
                </>} />

                <Route path='/error' element={<>
                    <div className='w-full'>
                        <UnexpectedError />
                    </div>
                </>} />
            </Routes>
        </>
    )
}