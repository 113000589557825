import { BrandVariants, createLightTheme, Theme } from '@fluentui/react-components'

const themeColors: BrandVariants = {
  10: "#08000E",
  20: "#21024D",
  30: "#32135A",
  40: "#3F2164",
  50: "#4B2E6E",
  60: "#583C78",
  70: "#654A82",
  80: "#1f004c",
  90: "#7E6697",
  100: "#8B75A1",
  110: "#9884AC",
  120: "#A593B6",
  130: "#B3A3C1",
  140: "#C0B3CC",
  150: "#CDC3D7",
  160: "#DBD3E2"
};

export const metaTheme: Theme = {
  ...createLightTheme(themeColors),
};