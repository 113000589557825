import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { ITimeEntryCopy } from "../../Models";
import { SpinButton, SpinButtonChangeEvent, SpinButtonOnChangeData } from '@fluentui/react-spinbutton';

interface ITimeEntryCopyEditComponent {
    comment: string,
    copyTimeEntry: ITimeEntryCopy,
    onTimeUpdate: Function,
    disabled: boolean
}

const TimeEntryCopyEditComponent = (props: ITimeEntryCopyEditComponent) => {
    const [dateString, setDateString] = useState<string>('');
    const [hours, setHours] = useState<number | undefined>(Math.floor(props.copyTimeEntry.timeLength / 60));
    const [minutes, setMinutes] = useState<number | undefined>(props.copyTimeEntry.timeLength % 60);

    useEffect(() => {
        setDateString(DateTime.fromJSDate(props.copyTimeEntry.date).toFormat('dd/LL/yyyy'));
    }, [props.copyTimeEntry])

    const onHoursChange = (event: SpinButtonChangeEvent, data: SpinButtonOnChangeData): void => {
        if(data && data.value){
            setHours(data.value);
        }
    };

    const onMinutesChange = (event: SpinButtonChangeEvent, data: SpinButtonOnChangeData): void => {
        if(data && data.value){
            var roundedNumber = (Math.round(data.value / 15) * 15);

            setMinutes(roundedNumber <= 45 ? roundedNumber : 45);
        }
    };


    return (<>
        <div className="flex flex-row justify-between px-3 py-1 items-center">
            <div className="truncate w-1/3 text-center break-words" title={props.comment}>{props.comment}</div>
            <div className='w-1/3 text-center'>{dateString}</div>
            <div className="flex flex-row w-1/3">
                <SpinButton
                    disabled={props.disabled}
                    value={hours}
                    min={0}
                    max={400}
                    step={1}
                    onChange={onHoursChange}
                />
                <SpinButton
                    disabled={props.disabled}
                    className="outline-0"
                    value={minutes}
                    min={0}
                    max={45}
                    step={15}
                    onChange={onMinutesChange}
                />
            </div>
        </div>
    </>);
}

export default TimeEntryCopyEditComponent;