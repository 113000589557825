import { Translate } from "react-i18nify";
import { useAppDispatch, useAppSelector, switchShowMaintenancePanel } from "../../Redux";
import { SetMaintainenceDatesComponent } from "./SetMaintainenceDatesComponent";
import { YearEndProcedureSettingComponent } from "./YearEndProcedureSettingComponent";

export const MaintenanceUpdatePanel = () => {
    const dispatch = useAppDispatch();
    const displayPanel = useAppSelector(s => s.administration.showMaintenancePanel);

    const panelDismiss = () => {
        dispatch(switchShowMaintenancePanel());
    }

    return (<>
        {
            displayPanel &&
            <div className="relative z-[1011]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-neutral bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 my-auto">
                            <div className="pointer-events-auto relative w-screen max-w-4xl">
                                <div className="absolute left-full top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                    <button type="button" className="relative rounded-md text-primary hover:text-primary-dark" onClick={panelDismiss}>
                                        <span className="absolute -inset-2.5"></span>
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6 stroke-primary stroke-1" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <h2 className="text-base font-semibold leading-6 text-primary" id="slide-over-title"><Translate value="App_Forms_Maintenance_Title" /></h2>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="flex flex-col space-y-10 px-5 justify-center items-center mt-10">
                                            <div className="flex flex-col space-y-5 w-full p-3">
                                                <SetMaintainenceDatesComponent />
                                                <YearEndProcedureSettingComponent />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>);
}