// import { useParallaxMouse } from "../../Utils";

export const Logo = () => {

	const obj1: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-object--1');
	const obj2: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-object--2');
	const obj3: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-object--3');
	const obj4: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-object--4');
	const obj5: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-object--5');
	const objM: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-object--m');
	const objects: Array<{ element: Element; effect: string; delta: string; direction?: string }> = [];

	if (obj1.length > 0) {
		objects.push({ element: obj1[0], effect: 'parallax', delta: '25', direction: 'follow' });
	}
	if (obj2.length > 0) {
		objects.push({ element: obj2[0], effect: 'parallax', delta: '20', direction: 'follow' });
	}
	if (obj3.length > 0) {
		objects.push({ element: obj3[0], effect: 'parallax', delta: '15', direction: 'follow' });
	}
	if (obj4.length > 0) {
		objects.push({ element: obj4[0], effect: 'parallax', delta: '10', direction: 'follow' });
	}
	if (obj5.length > 0) {
		objects.push({ element: obj5[0], effect: 'parallax', delta: '5', direction: 'follow' });
	}
	if (objM.length > 0) {
		objects.push({ element: objM[0], effect: 'parallax', delta: '5', direction: 'follow' });
	}

	// useParallaxMouse(objects);

	return (<>
		<svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" data-name="Ebene 1" width="220" height="90" viewBox="0 0 220 90">
			<path className="fill-[#ffbeaa] cursor-fx-object--1" d="m73.51,73.44c-3.92,0-7.12-3.86-8.64-7.96-2.09-5.66-6.53-5.02-9.18-4.64-.26.04-.5.07-.72.1-5.58.64-9.14-.73-10.61-4.07-1.77-4.04-1.37-11.22,1.24-21.96,1.64-6.78,5.38-18.18,11.9-18.35,6.21-.15,14.11,12.61,18.83,24.55,3.79,9.59,6.64,22.09,3.24,28.4-1.12,2.08-2.83,3.37-5.08,3.84h-.03c-.32.06-.64.08-.95.08Zm-15.26-14.13c2.72,0,6.07.92,7.84,5.72,1.4,3.79,4.54,7.61,8.15,7.05,1.88-.4,3.25-1.44,4.19-3.18,3.19-5.92.38-17.99-3.3-27.31-5.37-13.57-12.87-23.73-17.51-23.73-.03,0-.05,0-.08,0-4.14.11-8.03,6.44-10.68,17.36-2.53,10.45-2.96,17.36-1.31,21.13,1.23,2.8,4.25,3.88,9.27,3.3.2-.02.43-.06.68-.09.74-.11,1.7-.25,2.75-.25Z" />
			<path className="fill-[#ffd657] cursor-fx-object--2" d="m66.82,71.57c-3.18,0-5.83-2.46-8.41-4.85-1.87-1.74-3.81-3.53-5.91-4.36-.57-.23-1.95-.23-3.27-.22-1.67,0-3.4,0-4.61-.38-5.97-1.91-8.83-8.8-7.65-18.43,1.4-11.41,8.13-23.96,15.93-24.47h.03c.05,0,.11,0,.17,0,7.51,0,14.69,11.28,18.5,22.53,3.83,11.31,5.02,24.67-.95,29.02-1.37.83-2.64,1.17-3.83,1.17Zm-17.16-10.94c1.42,0,2.63.03,3.4.33,2.36.94,4.4,2.83,6.38,4.66,3.43,3.19,6.4,5.94,10.39,3.53,4.51-3.29,4.68-14.53.36-27.29-3.52-10.39-10.35-21.51-17.08-21.51-.04,0-.08,0-.12,0-5.57.38-12.9,9.97-14.52,23.15-.52,4.26-.92,14.41,6.62,16.82.98.31,2.66.31,4.15.31.15,0,.29,0,.43,0Z" />
			<path className="fill-[#64e100] cursor-fx-object--3" d="m58.4,69.88c-2.91,0-5.72-1.21-7.73-3.06-2.84-2.51-5.41-2.88-8.12-3.27-3.34-.48-6.79-.98-10.26-5.69-4.67-6.35-3.14-17.47.78-25.17,3.79-7.44,9.41-11.7,15.42-11.7h.09c7.68.12,13.97,8.57,17.3,16.43,4.22,9.97,5.58,23.13-.91,30.03l-.08.07c-2.01,1.65-4.28,2.35-6.48,2.35Zm-9.91-47.19c-5.35,0-10.42,3.92-13.9,10.77-4.3,8.44-4.69,18.28-.93,23.39,3.04,4.13,5.86,4.54,9.13,5.01,2.71.39,5.79.84,9.02,3.69,3,2.76,8.05,3.86,11.96.69,5.89-6.33,4.41-19.02.55-28.17-3.92-9.26-10.1-15.3-15.76-15.4-.02,0-.04,0-.06,0Z" />
			<path className="fill-[#cae900] cursor-fx-object--4" d="m49.69,68.19c-1.77,0-3.78-.28-6.05-.94l-.15-.06c-2.12-1.02-4.09-1.55-6.01-2.06-3.5-.94-6.8-1.83-9.96-5.49-5.03-6.12-5.46-16.47-1.06-25.18,3.85-7.61,10.37-11.95,17.42-11.59,6.73-.14,12.79,4.4,16.55,12.37,4.78,10.13,4.42,22.42-.85,28.58-1.86,2.17-4.96,4.37-9.9,4.37Zm-5.44-2.75c6.02,1.74,10.82.75,13.9-2.86,4.73-5.54,4.98-17.19.57-26.54-3.44-7.28-8.83-11.41-14.83-11.28h-.03s-.03,0-.03,0c-6.29-.32-12.15,3.61-15.66,10.54-4.07,8.06-3.74,17.56.82,23.1,2.76,3.2,5.66,3.98,9,4.88,1.97.53,4.02,1.08,6.27,2.15Z" />
			<g>
				<path className="fill-[#fffcd9] invisible lg:visible" d="m145.44,43.47h-2.23v-1.6h2.23v-1.59c0-.45.05-.85.15-1.19s.24-.65.42-.91c.18-.26.4-.48.65-.66s.52-.32.82-.43.61-.19.94-.24c.33-.05.67-.07,1.02-.07.24,0,.47,0,.71.03.24.02.47.04.69.07v1.59c-.11-.02-.23-.04-.35-.06-.12-.01-.24-.02-.35-.03-.12,0-.24-.02-.35-.02s-.23,0-.34,0c-.2,0-.39.01-.58.04-.19.02-.36.06-.52.12-.16.05-.31.13-.44.23s-.25.22-.34.36c-.09.14-.17.31-.22.51-.06.2-.08.44-.08.71v1.55h3.58v1.6h-3.58v8.11h-1.82v-8.11Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m153.83,39.58c-.15-.06-.28-.14-.39-.25-.11-.11-.19-.24-.26-.38-.06-.15-.09-.31-.09-.49s.03-.35.09-.5c.06-.15.15-.28.26-.38.11-.11.24-.19.39-.25s.32-.09.5-.09.35.03.49.09.28.14.39.25c.11.11.19.23.26.38.06.15.09.32.09.5s-.03.34-.09.49c-.06.15-.15.27-.26.38s-.24.19-.39.25c-.15.06-.31.08-.49.08s-.35-.03-.5-.08Zm-.41,2.29h1.82v9.72h-1.82v-9.72Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m158.84,41.86h1.82v2.33c.13-.45.3-.83.51-1.15.21-.32.46-.59.74-.8.29-.21.61-.37.97-.48.36-.11.75-.16,1.19-.16.52,0,.99.09,1.41.28.42.19.79.46,1.09.82s.53.79.69,1.31c.16.52.24,1.1.24,1.74v5.81h-1.83v-5.39c0-.48-.06-.9-.17-1.28-.11-.37-.27-.69-.49-.94-.21-.25-.47-.44-.78-.57-.31-.13-.67-.19-1.06-.19s-.75.06-1.06.19c-.31.13-.57.32-.78.57-.21.25-.37.56-.48.94-.11.37-.17.8-.17,1.28v5.39h-1.82v-9.72Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m173.78,51.67c-.41-.12-.79-.28-1.14-.5-.35-.22-.67-.48-.96-.78s-.53-.64-.73-1.02-.36-.79-.46-1.24-.16-.92-.16-1.41.05-.96.16-1.4.26-.86.46-1.24c.2-.38.45-.72.73-1.03.29-.3.6-.57.96-.78.35-.22.73-.38,1.14-.49.41-.11.85-.17,1.31-.17.33,0,.64.04.92.11.29.07.55.18.8.31s.47.3.67.49c.2.19.39.4.55.63.16.23.31.48.42.74.12.26.21.53.29.81v-2.84h1.83v9.72h-1.83v-2.83c-.11.42-.28.81-.49,1.18s-.47.7-.77.98-.65.51-1.05.67c-.4.16-.85.25-1.34.25-.46,0-.9-.06-1.31-.17Zm2.66-1.56c.28-.07.55-.18.79-.32.25-.14.47-.31.67-.51.2-.2.37-.43.52-.69.14-.26.25-.54.33-.86.08-.31.12-.65.12-1.01s-.04-.7-.12-1.01c-.08-.31-.19-.6-.33-.85-.14-.26-.32-.49-.52-.69-.2-.2-.43-.37-.67-.51-.25-.14-.51-.25-.79-.32s-.58-.11-.89-.11-.6.04-.88.11c-.28.07-.55.18-.79.32-.24.14-.46.31-.67.51-.2.2-.37.43-.52.69-.14.26-.25.54-.33.85-.08.31-.12.65-.12,1.01s.04.7.12,1.01c.08.31.19.6.33.86.14.26.32.49.52.69.2.2.42.37.67.51.24.14.51.25.79.32.28.07.58.11.88.11s.6-.04.88-.11Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m184.18,41.86h1.82v2.33c.13-.45.3-.83.51-1.15.21-.32.46-.59.74-.8.29-.21.61-.37.97-.48.36-.11.75-.16,1.19-.16.52,0,.99.09,1.41.28.42.19.79.46,1.09.82s.53.79.69,1.31c.16.52.24,1.1.24,1.74v5.81h-1.83v-5.39c0-.48-.06-.9-.17-1.28-.11-.37-.27-.69-.49-.94-.21-.25-.47-.44-.78-.57-.31-.13-.67-.19-1.06-.19s-.75.06-1.06.19c-.31.13-.57.32-.78.57-.21.25-.37.56-.48.94-.11.37-.17.8-.17,1.28v5.39h-1.82v-9.72Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m195.36,50.03l6.33-6.57h-6.01v-1.6h8.2v1.55l-6.35,6.57h6.45v1.6h-8.62v-1.55Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m86.04,51.56h3.3v-5.2c0-.34.03-.64.09-.9.06-.25.16-.47.28-.63.13-.17.3-.3.49-.39.2-.09.44-.13.71-.13s.52.04.72.13c.2.09.36.22.49.39.13.17.22.38.28.63.06.25.09.55.09.9v5.2h3.3v-5.2c0-.34.03-.64.09-.9.06-.25.16-.47.28-.63.13-.17.3-.3.49-.39.2-.09.44-.13.71-.13s.51.04.71.13c.2.09.36.22.49.39.13.17.22.38.28.63.06.25.09.55.09.9v5.2h3.29v-6.09c0-.62-.07-1.18-.22-1.66-.14-.49-.36-.9-.64-1.23-.28-.34-.62-.6-1.04-.77-.41-.18-.88-.27-1.42-.27-.39,0-.75.05-1.09.16-.34.1-.66.25-.94.45-.29.2-.54.45-.77.75-.23.29-.42.63-.58,1.02-.1-.38-.24-.72-.42-1.01-.18-.29-.4-.54-.66-.74-.26-.21-.56-.36-.9-.47-.33-.11-.69-.16-1.09-.16s-.76.05-1.08.15c-.32.1-.61.24-.87.43-.25.19-.48.41-.68.67-.19.26-.36.55-.49.88v-1.82h-3.3v9.72Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m110.88,51.87c.46,0,.9-.03,1.32-.1.43-.07.84-.18,1.23-.33.39-.15.77-.34,1.13-.57.36-.23.7-.51,1.03-.83l-1.54-1.95c-.21.22-.44.42-.67.58-.24.16-.48.3-.73.4-.24.1-.49.17-.75.22-.25.04-.51.07-.76.07-.23,0-.45-.02-.65-.06s-.4-.09-.58-.16c-.18-.07-.35-.16-.5-.27-.15-.11-.29-.22-.41-.35-.12-.13-.22-.28-.3-.44-.07-.16-.13-.33-.18-.51h7.25c.05-.63.03-1.22-.05-1.77-.07-.55-.21-1.04-.41-1.49-.19-.45-.44-.85-.75-1.2-.3-.35-.66-.64-1.06-.88-.4-.24-.84-.43-1.33-.55-.49-.13-1.01-.2-1.58-.2-.53,0-1.04.06-1.52.19-.47.12-.91.29-1.31.51-.4.22-.75.49-1.06.81-.31.31-.58.66-.79,1.04-.22.39-.39.8-.5,1.25-.11.45-.17.92-.17,1.41s.06.95.18,1.4c.12.45.29.86.51,1.25.23.39.51.73.84,1.04.33.3.7.57,1.12.79.42.22.88.39,1.38.5.5.12,1.04.19,1.61.19Zm-2.51-6.45c.02-.19.07-.36.13-.51.07-.16.15-.3.25-.43.1-.12.21-.24.34-.34.13-.1.27-.18.43-.24.16-.07.32-.12.49-.15.17-.04.36-.06.55-.06.29,0,.55.04.79.12.25.07.47.18.65.33.19.14.35.32.48.54.12.22.21.46.25.74h-4.37Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m133.19,51.87c.4,0,.77-.06,1.09-.18.33-.12.62-.28.88-.48.26-.21.49-.44.68-.71.2-.27.37-.55.51-.85v1.9h3.29v-9.72h-3.29v1.9c-.09-.2-.2-.39-.32-.58-.12-.19-.25-.36-.4-.52-.14-.17-.3-.32-.48-.45-.17-.14-.35-.25-.56-.35-.2-.1-.42-.17-.65-.22-.24-.06-.49-.08-.76-.08-.43,0-.84.06-1.23.18-.39.12-.76.29-1.09.5-.34.22-.64.48-.91.78-.27.3-.51.65-.7,1.04-.19.38-.34.8-.45,1.25-.11.45-.16.92-.16,1.42s.05.97.16,1.43c.11.45.26.86.45,1.25.19.38.43.72.7,1.03.27.3.58.57.91.78.34.22.7.39,1.09.5.39.12.8.18,1.23.18Zm1.11-2.82c-.21,0-.42-.02-.62-.07-.19-.05-.37-.12-.54-.21-.16-.1-.31-.22-.45-.35-.14-.14-.25-.29-.35-.47-.09-.17-.17-.36-.22-.57-.05-.21-.07-.44-.07-.68s.03-.47.07-.67c.06-.21.13-.4.22-.58.1-.17.22-.33.35-.47.14-.14.29-.25.45-.35.17-.1.35-.17.54-.21.2-.05.4-.07.62-.07s.41.02.61.07c.2.04.38.12.55.21.17.09.32.21.46.35.14.14.25.29.35.47.09.17.17.37.21.58.06.21.08.43.08.67s-.03.47-.08.68c-.05.21-.12.39-.21.57-.09.17-.21.33-.35.47-.14.14-.29.25-.46.35-.17.09-.35.16-.55.21-.19.04-.39.07-.61.07Z" />
				<path className="fill-[#fffcd9] invisible lg:visible" d="m123.96,51.73c.17,0,.35,0,.52-.02.18-.01.36-.03.53-.06.18-.02.36-.05.54-.08.19-.03.37-.07.56-.11v-2.63c-.07.02-.16.04-.25.06-.09.01-.19.02-.3.04-.1.01-.21.02-.32.03-.11,0-.22,0-.34,0-.27,0-.52-.02-.73-.07-.21-.06-.39-.15-.53-.27-.14-.12-.25-.29-.34-.49-.07-.21-.11-.46-.11-.77v-2.7h2.92v-2.8h-2.92v-3.16h-3.29v3.16h-1.96v2.8h1.96v3.31c0,.7.1,1.3.3,1.78.21.48.49.87.84,1.17.36.29.79.5,1.28.63.5.13,1.04.2,1.63.2Z" />
				<path className="fill-[#fffcd9] cursor-fx-object--m" d="m28.41,40.64h3.67v1.12c.17-.23.37-.44.59-.62.22-.18.46-.33.73-.46.27-.13.57-.22.89-.28.32-.06.68-.09,1.08-.09.41,0,.8.04,1.15.12s.67.2.95.36c.28.16.53.36.75.59s.39.5.54.8c.23-.34.48-.64.76-.87.28-.24.58-.43.9-.58.32-.15.66-.26,1.02-.33.36-.07.73-.1,1.11-.1.64,0,1.21.1,1.69.29.48.2.89.48,1.21.86.32.38.57.83.73,1.37.16.54.24,1.15.24,1.84v6.75h-3.66v-5.76c0-.38-.03-.71-.1-.99-.07-.28-.17-.52-.31-.71s-.32-.33-.54-.43c-.22-.1-.49-.14-.8-.14s-.57.05-.79.14c-.22.1-.4.24-.54.43s-.25.43-.32.71c-.07.28-.1.61-.1.99v5.76h-3.67v-5.76c0-.38-.03-.71-.1-.99-.07-.28-.17-.52-.31-.71s-.32-.33-.54-.43c-.22-.1-.49-.14-.8-.14s-.57.05-.79.14c-.22.1-.4.24-.54.43-.14.19-.25.43-.32.71-.07.28-.1.61-.1.99v5.76h-3.67v-10.77Z" />
			</g>
			<path className="fill-[#64e100] cursor-fx-object--5" d="m37.46,67.28c-11.82,0-21.43-9.61-21.43-21.43s9.61-21.43,21.43-21.43,21.43,9.61,21.43,21.43-9.61,21.43-21.43,21.43Zm0-40.76c-10.66,0-19.33,8.67-19.33,19.33s8.67,19.33,19.33,19.33,19.33-8.67,19.33-19.33-8.67-19.33-19.33-19.33Z" />
		</svg>
	</>)
}
