import { Translate } from "react-i18nify"
import { Add28Regular } from "@fluentui/react-icons"

interface IAddItemButton {
    onClick: Function
}

export const AddItemButton = (props: IAddItemButton) => {
    return (<>
        <div className="lg:flex lg:text-sm items-center space-x-2 text-primary cursor-pointer my-2" onClick={() => props.onClick()}>
            <Add28Regular className='text-primary font-light text-lg'></Add28Regular>
            <span><Translate value='App_Button_Add' /></span>
        </div>
    </>)
}