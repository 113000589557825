import { useAppDispatch, useAppSelector, switchShowTimeEntryCopyPanel, useCopyTimeRecordMutation, updateLoadingState } from '../../Redux';
import { Translate } from 'react-i18nify';
import { Calendar, DateRangeType, CalendarDayProps, DayOfWeek, CalendarMonthProps } from '@fluentui/react-calendar-compat'
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import TimeEntryCopyEditComponent from './TimeEntryCopyEditComponent';
import { addDateTimeOffset, transformMinutesToBookedTime } from '../../Utils/Utils';
import { ITimeEntryCopy } from '../../Models';
import { Switch } from '@fluentui/react-components';

const handleDayOutsideBounds = (element: HTMLElement, dayOutsideBounds?: string, remove?: boolean) => {
    if (dayOutsideBounds) {
        var classes = dayOutsideBounds.split(' ');
        for (var i = 0; i < classes.length - 1; i++) {
            if (remove) {
                element.classList.contains(classes[i]) && element.classList.remove(classes[i]);
            }
            else {
                element.classList.add(classes[i]);
            }
        }
    }
}

const TimeEntryCopyPanelComponent = () => {
    const dispatch = useAppDispatch();

    const dateRange = useAppSelector((state) => state.calendar.dateRange);
    const dateRangeType = useAppSelector((state) => state.calendar.dateRangeType);
    const showCopyPanel = useAppSelector((state) => state.timeEntries.showTimeEntryCopyPanel);
    const timeEntry = useAppSelector((state) => state.timeEntries.currentSelectedTimeEntry);
    const publicHolidays = useAppSelector((state) => state.publicHoliday.publicHolidays);
    const calendarStrings = useAppSelector((state) => state.calendar.calendarStrings);

    const [copyStartDate, setCopyStartDate] = useState<Date>();
    const [copyEndDate, setCopyEndDate] = useState<Date>();
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [selectedDates, setSelectedDates] = useState<ITimeEntryCopy[]>([]);
    const [overflowDatesString, setOverflowDatesString] = useState<string>('');
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

    const [copyTimeRecord, { isSuccess, isLoading, isError, data }] = useCopyTimeRecordMutation();

    const calendarDayProps: Partial<CalendarDayProps> = {
        customDayCellRef: (element, date, classNames) => {
            if (element) {
                var holidays = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == date.toDateString() })
                if (holidays.length > 0) {
                    element.classList.add('dayMarker');
                    element.title = holidays[0].localName;
                } else {
                    if (element.classList.contains('dayMarker')) {
                        element.classList.remove('dayMarker');
                    }
                }

                if (selectedDates.filter((t) => { return t.date.toDateString() == date.toDateString() }).length > 0) {
                    element.classList.add('copyDayMarker');
                } else {
                    if (element.classList.contains('copyDayMarker')) {
                        element.classList.remove('copyDayMarker');
                    }
                }

                if (isFormDisabled) {
                    // classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds)
                    handleDayOutsideBounds(element, classNames.dayOutsideBounds, false);
                } else {

                    handleDayOutsideBounds(element, classNames.dayOutsideBounds, true);
                    // if (classNames.dayOutsideBounds && element.classList.contains(classNames.dayOutsideBounds)) {
                    //     element.classList.remove(classNames.dayOutsideBounds)
                    // }
                }

                if (date.getDay() === 0 || date.getDay() === 6 || date.getDate() > (copyEndDate?.getDate() as number)) {
                    handleDayOutsideBounds(element, classNames.dayOutsideBounds, false);
                    (element.children[0] as HTMLButtonElement).disabled = true;
                }
            }
        }
    };

    useEffect(() => {
        if (timeEntry) {
            const workPackageEnd = DateTime.fromJSDate(new Date(timeEntry?.workingPackage?.to as Date));
            let start = dateRange[0];
            let end = dateRange[1];
            if (dateRangeType == DateRangeType.Week) {
                const now = DateTime.fromJSDate(new Date(timeEntry?.on as Date));
                start = now.set({ day: 1 }).toJSDate();
                end = now.set({ day: now.daysInMonth }).toJSDate();
            }

            setCopyStartDate(start);
            if (workPackageEnd.diff(DateTime.fromJSDate(end)).toMillis() < 0) {
                setCopyEndDate(workPackageEnd.toJSDate());
            } else {
                setCopyEndDate(end);
            }
        }
    }, [timeEntry])

    useEffect(() => {
        dispatch(updateLoadingState(isLoading));

        if (isSuccess) {
            if (data && data.length > 0) {
                let datesOverflow = data.map((t: any) => { return { 'date': new Date(t['date']), 'timeLength': Number(t.timeLength) } });
                setSelectedDates(datesOverflow);
                setOverflowDatesString(datesOverflow.map((t: ITimeEntryCopy) => DateTime.fromJSDate(t.date as Date).toFormat('dd/LL/yyyy')).join(', '));
                setIsFormDisabled(false);
            }
            else {
                setSelectedDates([]);
                onDismissCopyPanelClick();
            }
        }

        if (isError) {
            setIsFormDisabled(false);
        }
    }, [isSuccess, isLoading, isError])

    const onDaySelect = (date: Date, selectedDateRangeArray?: Date[] | undefined) => {
        if (date) {
            var filteredDates = selectedDates.filter((t) => { return t.date.toDateString() != date.toDateString() });
            if (filteredDates.length < selectedDates.length) {
                setSelectedDates([...filteredDates]);
            } else {
                setSelectedDates([...selectedDates, { 'date': addDateTimeOffset(date), timeLength: Number(timeEntry?.estimatedLength) }]);
            }
        }
    }

    const onTimeUpdate = function (date: Date, timeLength: number) {
        setSelectedDates(selectedDates.map(obj => {
            if (obj.date === date) {
                return { ...obj, timeLength };
            }

            return obj;
        }));
    }

    const onDismissCopyPanelClick = () => {
        setIsFormDisabled(false);
        setSelectedDates([]);
        setIsSelectAll(false);
        dispatch(switchShowTimeEntryCopyPanel());
    }

    const onSelectAllClick = () => {
        let selected = !isSelectAll
        if (selected) {
            let tempDate = DateTime.fromJSDate(addDateTimeOffset(dateRange[0]));
            let daysInMonth = tempDate.daysInMonth;
            let dates: ITimeEntryCopy[] = [];
            let index = 0;
            if (daysInMonth) {
                while (index < daysInMonth) {
                    let isFreeDay = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == tempDate.toJSDate().toDateString() }).length > 0;
                    if (tempDate.weekday < 6 && !isFreeDay) {
                        dates.push({ date: addDateTimeOffset(tempDate.toJSDate()), timeLength: timeEntry?.estimatedLength as number });
                    }

                    tempDate = tempDate.plus({ days: 1 });
                    index++;
                }

                setSelectedDates(dates);
            }
        } else {
            setSelectedDates([]);
        }

        setIsSelectAll(selected);
    }

    const onCopyClick = () => {
        copyTimeRecord({ id: timeEntry?.id, selectedDates });
        setIsFormDisabled(true);
    }

    return (<>
        {
            showCopyPanel &&
            <div className="relative z-[1011]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-neutral bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 my-auto">
                            <div className="pointer-events-auto relative w-screen max-w-7xl">
                                <div className="absolute left-full top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4 z-10">
                                    <button type="button" className="relative rounded-md text-primary hover:text-primary-dark" onClick={onDismissCopyPanelClick}>
                                        <span className="absolute -inset-2.5"></span>
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6 stroke-primary stroke-1" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <h2 className="text-lg font-bold leading-6 text-primary" id="slide-over-title"><Translate value="App_Label_Duplicate_Activity" /></h2>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="flex flex-col space-y-10 px-5 justify-center items-center mt-10 w-full">
                                            {
                                                timeEntry &&
                                                (
                                                    <>
                                                        <span className='p-3 text-primary font-semibold text-xs lg:text-base'>
                                                            <Translate value='App_Copy_Text' time={transformMinutesToBookedTime(timeEntry.estimatedLength)} comment={timeEntry.comment ?? ''} project={timeEntry.workingPackage?.title ?? ''} />
                                                        </span>
                                                        <span className={`p-3 font-semibold text-sm ${overflowDatesString.length > 0 ? 'block' : 'hidden'}`}>
                                                            <Translate value='App_Copy_Error_Message' datesOverflow={overflowDatesString} />
                                                        </span>
                                                        <div className='flex flex-col lg:flex-row w-full mt-5 space-x-3 sm:space-x-6 items-stretch lg:items-start'>
                                                            <div className="m-auto lg:m-0">
                                                                <Calendar
                                                                    showMonthPickerAsOverlay={false}
                                                                    isMonthPickerVisible={false}
                                                                    showGoToToday={false}
                                                                    value={copyStartDate}
                                                                    today={copyStartDate}
                                                                    minDate={copyStartDate}
                                                                    maxDate={copyEndDate}
                                                                    onSelectDate={onDaySelect}
                                                                    firstDayOfWeek={DayOfWeek.Monday}
                                                                    strings={calendarStrings}
                                                                    calendarDayProps={calendarDayProps}
                                                                // navigationIcons={{ leftNavigation: undefined, rightNavigation: undefined }}
                                                                />
                                                                <div className="flex flex-row justify-between w-full text-base text-black">
                                                                    <span><Translate value={isSelectAll ? 'App_Button_Deselect_All' : 'App_Button_Select_All'} /></span>
                                                                    <Switch checked={isSelectAll}
                                                                        labelPosition="before"
                                                                        disabled={isFormDisabled}
                                                                        onChange={() => onSelectAllClick()}
                                                                    />
                                                                </div>
                                                                <div className='flex items-center justify-center w-full h-9 bg-white bottom-0 right-0 overflow-visible'>
                                                                    <button type="button" onClick={onCopyClick} disabled={isFormDisabled || selectedDates.length == 0} className="rounded-sm bg-primary px-2.5 py-1 w-full text-sm font-semibold text-white shadow-sm hover:bg-primary/80 
                                                                                        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary
                                                                                        disabled:bg-gray-dark disabled:text-black disabled:cursor-not-allowed">
                                                                        <Translate value="App_Button_Save" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className='grow max-h-96 lg:max-h-max overflow-y-scroll lg:overflow-y-auto'>
                                                                {
                                                                    selectedDates.length > 0 &&
                                                                    (
                                                                        <div className='flex flex-col space-y-2 w-full'>
                                                                            <div className='divide-y divide-gray-dark'>
                                                                                <div className="flex flex-row justify-between items-center bg-primary text-tertiary h-10 px-2 text-sm font-semibold">
                                                                                    <div className='w-1/3 text-center'><Translate value="App_Label_Description" /></div>
                                                                                    <div className='w-1/3 text-center'><Translate value="App_Label_On" /></div>
                                                                                    <div className='w-1/3 text-center'><Translate value="App_Label_Duration" /></div>
                                                                                </div>
                                                                                {
                                                                                    selectedDates.map((t, i) =>
                                                                                        <TimeEntryCopyEditComponent key={i}
                                                                                            disabled={isFormDisabled}
                                                                                            comment={timeEntry.comment as string}
                                                                                            copyTimeEntry={t}
                                                                                            onTimeUpdate={onTimeUpdate} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    );
}

export default TimeEntryCopyPanelComponent;