import { Translate } from "react-i18nify";

interface NoUserProfileProps{
    userLogin: string
}

export const NoUserProfile = (props: NoUserProfileProps) => {
    return (<>
        <div className="mx-auto w-full h-full md:w-4/5">
            <div className="w-full mx-4 text-lg">
                <p className='font-bold'><Translate value='App_Info_No_User_Found' email={props.userLogin} /></p>
                <p><Translate value='App_Info_No_User_Found_Steps' /></p>
                <div><Translate value='App_Info_No_User_Found_Step_Invite' /></div>
                <div><Translate value='App_Info_No_User_Found_Step_Cache' /></div>
                <div><Translate value='App_Info_No_User_Found_Step_Sign' /></div>
                <div><Translate value='App_Info_No_User_Found_Step_Incognito' /></div>
                <br />
                <br />
                <p><Translate value='App_Info_Please' /> <a href="mailto:myIT@metafinanz.de"><Translate value='App_Info_Contact_Admin' /></a><Translate value='App_Info_Or_Register' /> <a href="https://metafinanz.de/zeiterfassung/" target="_blank"><Translate value='App_Info_Here'/></a></p>
            </div>
        </div>
    </>)
}