export const translations = {
    /* English */
    Lang_1033: {
        App_Date_Locale: 'en',
        App_SignOut: 'Sign out',

        App_Lang_Short: 'EN',

        App_Language_English: 'English',
        App_Language_German: 'German',

        App_Grid_Col_Title: 'Title',
        App_Grid_Col_Monday: 'Mon',
        App_Grid_Col_Tuesday: 'Tue',
        App_Grid_Col_Wednesday: 'Wed',
        App_Grid_Col_Thursday: 'Thu',
        App_Grid_Col_Friday: 'Fri',
        App_Grid_Col_Saturday: 'Sat',
        App_Grid_Col_Sunday: 'Sun',
        App_Grid_Col_Monday_Short: 'M',
        App_Grid_Col_Tuesday_Short: 'T',
        App_Grid_Col_Wednesday_Short: 'W',
        App_Grid_Col_Thursday_Short: 'T',
        App_Grid_Col_Friday_Short: 'F',
        App_Grid_Col_Saturday_Short: 'S',
        App_Grid_Col_Sunday_Short: 'S',
        App_Grid_Col_Total: 'Sum/Week',
        App_Grid_Project: 'Project',
        App_Grid_Booked_Time: 'Time',
        App_Grid_Booked_On: 'Booking Date',

        App_Grid_Label_Weekend: 'Weekend',

        App_Grid_ProjectTitleInfo: "The name of the project in CRM is:",
        App_Grid_TotalPT: 'Total PT',
        App_Grid_AvailablePT: 'AvailablePT:',
        App_Grid_Page: 'Page:',
        App_Grid_Page_Size: 'Page size:',
        App_Grid_Page_Next: 'Next',
        App_Grid_Page_Previous: 'Previous',
        App_Grid_DailyTotal: 'Daily Total',

        App_Settings_Language: 'Language',
        App_Settings_BookingFormat: 'Booking Format',

        App_Button_Save: 'Save',
        App_Button_Edit: 'Edit',
        App_Button_Cancel: 'Cancel',
        App_Button_Close: 'Close',
        App_Button_Delete: 'Delete',
        App_Button_Rename: 'Rename',
        App_Button_Resolved: 'Resolved',
        App_Button_Refresh: 'Retry',
        App_Button_Revert: 'Revert',
        App_Button_Download: 'Download',
        App_Button_Add: 'Add',
        App_Button_Add_New: 'Add new',
        App_Button_Yes: 'Yes',
        App_Button_No: 'No',
        App_Button_Reset: 'Refresh',
        App_Button_Copy: 'Copy',
        App_Button_Exit: 'Exit',
        App_Button_Help: 'Help',
        App_Button_Back: 'Back',
        App_Button_Settings: 'Settings',
        App_Button_Add_Private_Project: 'Add personal project',
        App_Button_Add_Time_Record: 'Add activity record',
        App_Button_Home: 'Home',
        App_Button_Activity_Templates: 'Activity templates',
        App_Button_Changelog: 'Changelog',
        App_Button_Notifications: 'Notifications',
        App_Button_Download_Recording: 'Download',
        App_Button_Select_All: 'Select all (working days)',
        App_Button_Deselect_All: 'Deselect all (working days)',
        App_Button_Calendar_Week: 'Week view',
        App_Button_Calendar_Month: 'Month view',
        App_Button_Maintenance: "Maintenance setup",
        App_Button_User_Preferences: "Preferences",
        App_Button_Show_Hidden_Projects: "Show Hidden Projects",
        App_Button_Hide_Hidden_Projects: "Hide Hidden Projects",

        App_Notification_Dismiss: "Dismiss notification",
        App_Notification_PopOut: "Display activity recording",

        App_Header_Label_Status: 'Status',
        App_Header_Label_Date: 'Date',
        App_Header_Label_Time: 'Time',
        App_Header_Label_Comment: 'Description',
        App_Header_Label_Edit: 'Edit',
        App_Header_Label_Copy: 'Copy',
        App_Header_Label_Delete: 'Delete',

        App_Label_Hours: 'Hours',
        App_Label_Minutes: 'Minutes',
        App_Label_Duration: 'Duration',
        App_Label_On: 'On',
        App_Label_Action: 'Action',
        App_Label_IncludeWeekend: 'Include weekends',
        App_Label_Dropdown_Select_All_Projects: 'Select all projects',
        App_Label_Sum_Month: 'Total Booked this month %{hours}h',
        App_Label_Sum_Week: 'Total Booked this week %{hours}h',
        App_Label_Description: 'Description',
        App_Label_Drop_To_Remove: 'Drop here to remove from group',
        App_Label_Ungroupped_Projects: 'Ungrouped projects',
        App_Label_Auto_Increment_Enabled: 'Is autoincrement enabled',
        App_Label_Auto_Increment_Skip_Holidays_Enabled: 'Is autoincrement skip holidays',
        App_Label_Auto_Increment_Hours_Threshold: 'Autoincrement hours threshold',
        App_Label_Duplicate_Activity: 'Duplicate activity',
        App_Label_Billing_Type: 'Billing Type: ',

        App_Label_Description_Auto_Increment_Enabled: 'Do you want to automatically jump to the next day if you save a activity bigger than a certain threshold?',
        App_Label_Description_Auto_Increment_Skip_Holidays_Enabled: 'Do you want to automatically jump to the next working day if you save a timerecording bigger than a certain threshold?',
        App_Label_Description_Auto_Increment_Hours_Threshold: 'The threshold - if the booked activity is bigger or equal the threshold the date will be increased.',

        App_Forms_ProjectGroupTitle: 'Title',
        App_Forms_ProjectTitle: 'Project title',
        App_Forms_ProjectDescription: 'Project description',
        App_Forms_StartingFrom: 'Starting From',
        App_Forms_EndDate: 'End Date',
        App_Forms_Favorite: 'Favorite',
        App_Forms_Hide_Project: 'Hidden',
        App_Forms_Clear: 'Clear',
        App_Forms_Description: 'Activity Description (required)',
        App_Forms_Activity_Template_Description: 'Activity Description',
        App_Forms_Activity_Template_Name: 'Activity Template Name',
        App_Form_Activity_Template_Name_Info: "This name will appear in the dropdown menu when you're creating a new activity.",
        App_Form_Activity_Template_Description_Info: 'This acts as a pre-filled comment for your activity.',
        App_Form_Activity_Template_Time_Info: 'This duration will be automatically added to your activity.(0 value does nothing)',
        App_Forms_TotalBooked: 'Total booked:',
        App_Forms_TotalHoursBookedMonth: 'Booked this month %{hours}h',
        App_Forms_TotalHoursBookedWeek: 'Booked this week %{hours}h',
        App_Forms_Select_Activity_Template: 'Select activity template',
        App_Forms_Country_Picker: 'Holiday Calendar',
        App_Forms_From: 'From',
        App_Forms_Until: 'Until',
        App_Forms_Dropdown_Projects: 'Projects',
        App_Forms_Dropdown_Projects_Placeholder: 'You can export times for single, multiple or all projects',
        App_Forms_User_Settings_Panel_Title: 'Activity recording settings',
        App_Forms_Maintenance_Title: 'Maintenance Update Panel',
        App_Forms_Maintenance_StartDate: 'Start',
        App_Forms_Maintenance_EndDate: 'End',
        App_Forms_Maintenance_Time: 'Exact Time',
        App_Forms_User_Preferences_Auto_Day_Incrementation_Title: 'Auto Date Increment',
        App_Forms_User_Auto_Day_Increment_Info: 'Date in the input field "On" will switch to the next available booking date when you booked more than eight hours on the current day on this workpackage',

        App_Info_Confirm_Delete_Title: 'Delete activity recording',
        App_Info_Confirm_Delete: 'Are you sure you want to delete this activity recording?',
        App_Info_Confirm_Bulk_Delete_Title: 'Bulk delete activity recording',
        App_Info_Confirm_Bulk_Delete: 'Are you sure you want to delete this activity recordings?',
        App_Info_PublicHoliday: 'Public holiday - %{name}',
        App_Info_Confirm_Booking_PublicHoliday_Title: 'Activity recording on a public holiday',
        App_Info_Confirm_Booking_PublicHoliday: 'Are you sure you want book an activity recording on a public holiday?',
        App_Info_Country_Selection_Title: 'Country selection',
        App_Info_Country_Selection: 'No holiday calendar selected. Please select a calendar that will display the public holidays in your country.',
        App_Info_Empty_Notifications: 'There are no new notifications at the moment...',
        App_Info_Invoice_Booking_Closure_On_Project: 'There is a booking closure set for this project on %{date}. All activity recordings create, updated or deleted before this date will not be synced in the metaBusinessSystem.',
        App_Info_ClosedWeek: 'Activity Recordings already completed!',
        App_Info_No_Project_For_Current_Period: 'There are no projects to display for the current selected period.',
        App_Info_Contact_Manager_Or: 'Please contact your project manager or our ',
        App_Info_Team: 'Project Onboarding team.',
        App_Info_No_User_Found: 'No user profile was found for the email %{email}.',
        App_Info_No_User_Found_Steps: 'To solve the issue please try one of the following steps:',
        App_Info_No_User_Found_Step_Invite: '1. if you just accepted our invitation, please give us some time to assign your project (up to 3 days)',
        App_Info_No_User_Found_Step_Cache: '2. delete your browser cache and cookies for all time',
        App_Info_No_User_Found_Step_Sign: '3. sign-out and sign-in again',
        App_Info_No_User_Found_Step_Incognito: '4. access the application in incognito mode.',
        App_Info_Please: 'If the issue still persists, please',
        App_Info_Contact_Admin: 'contact an administrator ',
        App_Info_Or_Register: 'or register',
        App_Info_Here: 'here',
        App_Info_Unresolved_Conflicts_Error: 'ERROR detected:',
        App_Info_Unresolved_Conflicts_Activities_Not_Synced: "Some of your activity recordings couldn't be synched properly. Please solve these faulty activity recordings.",
        App_Info_Unresolved_Conflicts_Revert_Info: 'If your activity recording was previously synced in the business system, you can use the ’Revert’ button to reset it to it’s previous state.',
        App_Info_Unresolved_Conflicts_Deletion_Notice: 'Otherwise, these will get deleted at the beginning of next month and won’t be invoiced to the customer nor credited to the supplier.',
        App_Info_Unresolved_Conflicts_Click_Here: 'Please click here',
        App_Info_Unresolved_Conflicts_Info_Question: ' for the user manual or contact us via ',
        App_Info_Unresolved_Conflicts_Info_End: ' for further assistance.',
        App_Info_Contact_Billing: 'Billing@metafinanz.de',
        App_Info_Teach_Bubble_Title: 'Welcome to timetable 2.0',
        App_Info_Teach_Bubble_Message: 'To start booking time, click on a project!',
        App_Info_Download_Time_Entries: 'Here you can download your activity recordings. Please select the time period.',
        App_Info_Dropdown_Projects_No_Selection: 'You have to select at least one project from the multi-select dropdown.',
        App_Info_No_Booked_Times: 'No activities recorded for the period %{timePeriod}',
        App_Info_Maintenance_Hours: 'Hours',
        App_Info_Maintenance_Minutes: 'Minutes',
        App_Info_Maintenance_Seconds: 'Seconds',
        App_Info_Maintenance_Text: 'Timetable will be back in',
        App_Info_Activity_Templates_Page_Create: `You can create multiple activity templates, each with its own unique settings. These templates simplify the creation of future activities by pre-filling essential details.`,
        App_Info_Activity_Templates_Page_Create_Steps: `1.Activity Template Name: Assign a name to your template. This name will appear in the dropdown menu when you're creating a new activity, helping you quickly identify the right template.
        2.Activity Description: Provide a description for your template. This acts as a pre-filled comment for your activity, giving you a head start on detailing the purpose or nature of the activity.
        3.Duration: Set a default duration for the activity. This duration will be automatically added to your activity, saving you time and ensuring consistency in scheduling similar activities.`,
        App_Info_Activity_Templates_Page_How_To : `Using a Template: When creating a new activity, simply select the desired template from the dropdown menu. The activity will automatically be populated with the template's description and duration, streamlining your scheduling process.`,

        //Time Recordings notifications
        App_Notification_TimeRecording_Create_Success: 'Activity recording was successfully created!',
        App_Notification_TimeRecording_Create_Error: 'There was an error on creating a new activity recording!',
        App_Notification_TimeRecording_Update_Success: 'Activity recording was successfully updated!',
        App_Notification_TimeRecording_Update_Error: 'There was an error on updating the activity recording!',
        App_Notification_TimeRecording_Delete_Success: 'Activity recording was successfully deleted!',
        App_Notification_TimeRecording_Delete_Error: 'There was an error on deleting the activity recording!',
        App_Notification_TimeRecording_Error_MissingComment: 'Activity recording comment is missing!',
        App_Notification_TimeRecording_Error_Invoice_Non_Bookable: 'Your activity recording cannot be synced with the metaBusinessSystem because the project selected time of the activity recording is already part of an invoice closure. Please get in touch with the colleagues of the Project Office.',
        App_Notification_TimeRecording_Error_Non_Bookable: 'Your activity recording cannot be synced with the metaBusinessSystem because the project time recording is no longer bookable. Please get in touch with the colleagues of the Project Office.',
        App_Notification_TimeRecording_Copy_Success: 'Activity recording copy was successfully!',
        App_Notification_TimeRecording_Copy_Error: 'There was an error on activity recording copy!',
        App_Notification_TimeRecording_Copy_NotEnoughTime: 'Unable to copy activity recording',

        //User Project notifications
        App_Notification_Project_Create_Success: 'Private project has been added successfully!',
        App_Notification_Project_Create_Error: 'There was an error on adding the private project!',
        App_Notification_Project_Update_Success: 'Project has been successfully updated!',
        App_Notification_Project_Update_Error: 'There was an error on updating the project!',
        App_Notification_Project_Delete_Success: 'Project has been successfully removed!',
        App_Notification_Project_Delete_Error: 'There was an error on deleting the private project!',
        App_Notification_Project_Error_NoTitle: 'Project title cannot be empty!',
        App_Notification_Project_Error_EmptyStartDate: 'Starting date cannot be empty!',
        App_Notification_Project_Error_EmptyEndDate: 'End date cannot be empty!',
        App_Notification_Project_Error_InvalidStartDate: 'Starting date must be greater than ending date!',

        //Project Groups notifications
        App_Notification_Project_Group_Create_Success: 'Project group has been added successfully!',
        App_Notification_Project_Group_Create_Error: 'There was an error on adding the project group!',
        App_Notification_Project_Group_Update_Success: 'Project group has been successfully updated!',
        App_Notification_Project_Group_Update_Error: 'There was an error on updating the project group!',
        App_Notification_Project_Group_Delete_Success: 'Project group has been successfully removed!',
        App_Notification_Project_Group_Delete_Error: 'There was an error on deleting the project group!',
        App_Notification_Project_Group_Error_NoTitle: 'Project group title cannot be empty!',

        //Skills notifications
        App_Notification_Skill_Create_Success: 'Activity template was successfully added!',
        App_Notification_Skill_Create_Error: 'There was an error on creating the Activity template!',
        App_Notification_Skill_Update_Success: 'Activity template was successfully updated!',
        App_Notification_Skill_Update_Error: 'There was an error on updating the Activity template!',
        App_Notification_Skill_Delete_Success: 'Activity template was successfully removed!',
        App_Notification_Skill_Delete_Error: 'There was an error on deleting the Activity template!',
        App_Notification_Skill_Error_MissingName: 'Activity template name cannot be empty!',
        App_Notification_Skill_Error_MissingComment: 'Activity template description cannot be empty!',

        //Booking Closure
        App_Notification_BookingClosure_Success: 'Booking Closure was successfully set!',
        App_Notification_BookingClosure_Error: 'There was an error on setting the Booking Closure. Please try again.',
        App_Notification_BookingClosure_Reset_Success: 'Booking Closure refresh was successful!',
        App_Notification_BookingClosure_Reset_Error: 'There was an error on Booking Closure refresh. Please try again.',

        //Ribbon notifications
        App_Notification_Ribbon_Language_Update_Error: 'There was an error on updating the language!',
        App_Notification_Ribbon_BookingFormat_Update_Error: 'There was a error on updating the booking format!',

        //Export Time notifications
        App_Notification_Export_InvalidDate: 'Please select a valid time period!',
        App_Notification_Export_NoRecordings: 'There were no activity recordings found for the selected time period.',

        App_Notification_Error_StartDateNotInTisMonth: 'From date is not in the current month!',
        App_Notification_Error_EndDateNotInTisMonth: 'Until date is not in the current month!',

        App_Copy_Text: 'Select the days for which the activity recording with the comment %{comment} shall be copied on the work package %{project}.',    
        App_Copy_Error_Message: 'Your Working Package does not have enough budget left to copy your time recording for all of your selected dates. The time recording was not copied for the following dates: %{datesOverflow}'
    },
    /* German */
    Lang_1031: {        
        App_Date_Locale: 'de',
        App_SignOut: 'Abmelden',

        App_Lang_Short: 'DE',

        App_Language_English: 'English',
        App_Language_German: 'Deutsch',

        App_Grid_Col_Title: 'Titel',
        App_Grid_Col_Monday: 'Mo.',
        App_Grid_Col_Tuesday: 'Di.',
        App_Grid_Col_Wednesday: 'Mi.',
        App_Grid_Col_Thursday: 'Do.',
        App_Grid_Col_Friday: 'Fr.',
        App_Grid_Col_Saturday: 'Sa.',
        App_Grid_Col_Sunday: 'So.',
        App_Grid_Col_Monday_Short: 'M',
        App_Grid_Col_Tuesday_Short: 'D',
        App_Grid_Col_Wednesday_Short: 'M',
        App_Grid_Col_Thursday_Short: 'D',
        App_Grid_Col_Friday_Short: 'F',
        App_Grid_Col_Saturday_Short: 'S',
        App_Grid_Col_Sunday_Short: 'S',
        App_Grid_Col_Total: 'Summe/Woche',
        App_Grid_Project: 'Projekt',
        App_Grid_Booked_Time: 'Leistung',
        App_Grid_Booked_On: 'Buchungstag',

        App_Grid_Label_Weekend: 'Wochenende',

        App_Grid_ProjectTitleInfo: "Der Name des Projekts im metaBS ist:",
        App_Grid_TotalPT: 'Total PT',
        App_Grid_AvailablePT: 'AvailablePT:',
        App_Grid_Page: 'Seite:',
        App_Grid_Page_Size: 'Seitengröße:',
        App_Grid_Page_Next: 'Nächste Seite',
        App_Grid_Page_Previous: 'Vorherige Seite',
        App_Grid_DailyTotal: 'Tages Gesamtsumme',

        App_Settings_Language: 'Sprache',
        App_Settings_BookingFormat: 'Buchungsformat',

        App_Button_Save: 'Speichern',
        App_Button_Edit: 'Bearbeiten',
        App_Button_Cancel: 'Abbrechen',
        App_Button_Close: 'Schließen',
        App_Button_Delete: 'Löschen',
        App_Button_Rename: 'Umbenennen',
        App_Button_Resolved: 'Aufgelöst',
        App_Button_Refresh: 'Wiederholen',
        App_Button_Revert: 'Zurücksetzen',
        App_Button_Add: 'Hinzufügen',
        App_Button_Add_New: 'Neu hinzufügen',
        App_Button_Download: 'Herunterladen',
        App_Button_Yes: 'Ja',
        App_Button_No: 'Nein',
        App_Button_Reset: 'Aktualisieren',
        App_Button_Copy: 'Kopieren',
        App_Button_Exit: 'Verlassen',
        App_Button_Help: 'Hilfe',
        App_Button_Back: 'Zurück',
        App_Button_Settings: 'Einstellungen',
        App_Button_Add_Private_Project: 'Persönliches Projekt hinzufügen',
        App_Button_Add_Time_Record: 'Leistungserfassungen hinzufügen',
        App_Button_Home: 'Home',
        App_Button_Activity_Templates: 'Aktivitätsvorlagen',
        App_Button_Notifications: 'Benachrichtigungen',
        App_Button_BookingClosure: 'Buchungsschluss',
        App_Button_Download_Recording: 'Herunterladen',
        App_Button_Select_All: 'Alle auswählen (Arbeitstage)',
        App_Button_Deselect_All: 'Alle abwählen (Arbeitstage)',
        App_Button_Calendar_Week: 'Wochenansicht',
        App_Button_Calendar_Month: 'Monatsansicht',
        App_Button_Maintenance: "Wartungseinstellung",
        App_Button_Changelog: 'Changelog',
        App_Button_User_Preferences: "User einstellungen",
        App_Button_Show_Hidden_Projects: "Versteckte Projekte anzeigen",
        App_Button_Hide_Hidden_Projects: "Versteckte Projekte ausblenden",

        App_Notification_Dismiss: "Benachrichtigung abbrechen",
        App_Notification_PopOut: "Leistungserfassungen anzeigen",

        App_Header_Label_Status: 'Status',
        App_Header_Label_Date: 'Datum',
        App_Header_Label_Time: 'Zeit',
        App_Header_Label_Comment: 'Aktivitätsbeschreibung',
        App_Header_Label_Edit: 'Bearbeiten',
        App_Header_Label_Copy: 'Kopieren',
        App_Header_Label_Delete: 'Löschen',

        App_Label_Hours: 'Stunden',
        App_Label_Minutes: 'Minuten',
        App_Label_Duration: 'Dauer',
        App_Label_On: 'Am',
        App_Label_Action: 'Aktion',
        App_Label_BookingClosure: 'Buchungsabschluss',
        App_Label_BookingClosureRefresh: 'Buchungsabschluss Aktualisierung',
        App_Label_IncludeWeekend: 'Wochenenden einschließen',
        App_Label_Dropdown_Select_All_Projects: 'Alle Projekte auswählen',
        App_Label_Sum_Month: 'Diesen Monat total gebucht %{hours}h',
        App_Label_Sum_Week: 'Diese Woche total gebucht %{hours}h',
        App_Label_Drop_To_Remove: 'Hier ablegen, um es aus der Gruppe zu entfernen',
        App_Label_Ungroupped_Projects: 'Projekte ohne Gruppe',
        App_Label_Auto_Increment_Enabled: 'Automatisch zum nächsten Tag',
        App_Label_Auto_Increment_Skip_Holidays_Enabled: 'Automatisch zum nächsten Arbeitstag',
        App_Label_Auto_Increment_Hours_Threshold: 'Stunden Schwellwert für Autoerhöhung',
        App_Label_Duplicate_Activity: 'Doppelte Leistungserfassung',
        App_Label_Billing_Type: 'Abrechnungsart: ',

        App_Label_Description_Auto_Increment_Enabled: 'Möchtest du automatisch zum nächsten Tag springen falls ein bestimmter Schwellwert überschritten wird?',
        App_Label_Description_Auto_Increment_Skip_Holidays_Enabled: 'Möchtest du automatisch zum nächsten Arbeitstag springen falls ein bestimmter Schwellwert überschritten wird?',
        App_Label_Description_Auto_Increment_Hours_Threshold: 'Der Schwellwert - wenn die gebuchte Aktivität größer oder gleich diesem ist wird das Datum erhöht.',

        App_Forms_ProjectGroupTitle: 'Titel',
        App_Forms_ProjectTitle: 'Projekt Titel',
        App_Forms_ProjectDescription: 'Project Beschreibung',
        App_Forms_StartingFrom: 'Start',
        App_Forms_EndDate: 'Ende',
        App_Forms_Favorite: 'Favorit',
        App_Forms_Hide_Project: 'Versteckt',
        App_Forms_Clear: 'Leeren',
        App_Forms_Description: 'Aktivitätsbeschreibung (erforderlich)',
        App_Forms_Activity_Template_Description: 'Aktivitätsbeschreibung',
        App_Forms_Activity_Template_Name: 'Aktivitätsvorlage Name',
        App_Form_Activity_Template_Name_Info: "Dieser Name wird im Dropdown-Menü angezeigt, wenn Sie eine neue Leistungerfassung erstellen.",
        App_Form_Activity_Template_Description_Info: 'Dies dient als vorab ausgefüllter Kommentar für Ihre Leistungerfassung.',
        App_Form_Activity_Template_Time_Info: 'Diese Dauer wird automatisch zu Ihrer Leistungerfassung hinzugefügt. (Wert 0 bewirkt nichts)',
        App_Forms_TotalBooked: 'Total gebucht:',
        App_Forms_TotalHoursBookedMonth: 'diesen Monat gebucht %{hours}h',
        App_Forms_TotalHoursBookedWeek: 'diese Woche gebucht %{hours}h',
        App_Forms_Select_Activity_Template: 'Wähle Aktivitätsvorlage',
        App_Forms_Country_Picker: 'Feiertagskalender',
        App_Forms_From: 'Von',
        App_Forms_Until: 'Bis',
        App_Forms_Dropdown_Projects: 'Projekte',
        App_Forms_Dropdown_Projects_Placeholder: 'You can export times for single, multiple or all projects',
        App_Forms_User_Settings_Panel_Title: 'Leistungserfassung Einstellungen',
        App_Forms_Maintenance_Title: 'Wartungs Update Panel',
        App_Forms_Maintenance_StartDate: 'Start',
        App_Forms_Maintenance_EndDate: 'Ende',
        App_Forms_Maintenance_Time: 'Genaue Zeit',
        App_Forms_User_Preferences_Auto_Day_Incrementation_Title: 'Nächster Buchungstag',
        App_Forms_User_Auto_Day_Increment_Info: 'Datum im Eingabefeld "Am" schaltet auf das nächste verfügbare Buchungsdatum um, wenn du mehr als acht Stunden am aktuellen Tag auf dieses Arbeitspaket gebucht hast',

        App_Info_Confirm_Delete_Title: 'Leistungserfassung löschen',
        App_Info_Confirm_Delete: 'Bist du sicher, dass du diese Leistungserfassung löschen möchtest?',
        App_Info_Confirm_Bulk_Delete_Title: 'Leistungserfassungen löschen',
        App_Info_Confirm_Bulk_Delete: 'Bist du sicher, dass du diese Leistungserfassungen löschen möchtest?',
        App_Info_PublicHoliday: 'Feiertag - %{name}',
        App_Info_Confirm_Booking_PublicHoliday_Title: 'Leistungserfassung an einem Feiertag angelegen',
        App_Info_Confirm_Booking_PublicHoliday: 'Bist du sicher, dass du die Leistungserfassung an einem Feiertag angelegt möchtest?',
        App_Info_Country_Selection_Title: 'Länderauswahl',
        App_Info_Country_Selection: 'Bisher wurde kein Feiertagskalender ausgewählt. Bitte wähle einen Kalender aus, damit die Feiertage in deinem Land dargestellt werden.',
        App_Info_Empty_Notifications: 'Im Moment gibt es keine neuen Benachrichtigungen...',
        App_Info_Invoice_Booking_Closure_On_Project: 'There is a booking closure set for this project on %{date}. All activity recordings create, updated or deleted before this date will not be synced in the metaBusinessSystem.',
        App_Info_BookingClosure_Dialog_Message: 'Wochenbuchungsabschluss für {{day}} setzen?',
        App_Info_BookingClosureRefresh_Dialog_Message: 'Bist Du sicher?',
        App_Info_ClosedWeek: 'Buchungen bereits abgeschlossen!',
        App_Info_No_Project_For_Current_Period: 'Für die aktuelle ausgewählte Zeitraum sind keine Projekte vorhanden.',
        App_Info_Contact_Manager_Or: 'Bitte wenden Sie sich an Ihren Projektmanager oder an unser ',
        App_Info_Team: 'Project Onboarding Team.',
        App_Info_No_User_Found: 'Für die E-Mail %{email} wurde kein Benutzerprofil gefunden.',
        App_Info_No_User_Found_Steps: 'Bitte gehen Sie wie folgt vor:',
        App_Info_No_User_Found_Step_Invite: '1. falls sie erst gerade ihre Einladung akzeptiert haben, kann es bis zu 3 Tagen dauern bis wir Ihr Projekt zugeordnet haben',
        App_Info_No_User_Found_Step_Cache: '2. löschen Sie den Browser-Cache und die Cookies für die gesamte Zeit',
        App_Info_No_User_Found_Step_Sign: '3. melden Sie sich von der Anwendung ab und wieder an',
        App_Info_No_User_Found_Step_Incognito: '4. öffnen Sie die Anwendung in einem Inkognito-Fenster',
        App_Info_Please: 'Falls Sie sich weiterhin nicht an der Anwendung anmelden können, wenden Sie sich',
        App_Info_Contact_Admin: 'bitte an einen Administrator ',
        App_Info_Or_Register: 'oder registrieren Sie sich',
        App_Info_Here: 'hier.',
        App_Info_Unresolved_Conflicts_Error: 'FEHLER aufgetreten:',
        App_Info_Unresolved_Conflicts_Activities_Not_Synced: 'Einige deiner Leistungserfassungen wurden nicht korrekt synchronisiert – siehe nachfolgend.Bitte löse deine fehlerhaften Leistungserfassungen auf.',
        App_Info_Unresolved_Conflicts_Revert_Info: 'If your activity recording was previously synced in the business system, you can use the ’Revert’ button to reset it to it’s previous state.',
        App_Info_Unresolved_Conflicts_Deletion_Notice: 'Diese werden ansonsten zu Beginn des Folgemonats gelöscht und können weder an den Kunden verrechnet noch dem Dienstleister ausgezahlt werden.',
        App_Info_Unresolved_Conflicts_Click_Here: 'Hier',
        App_Info_Unresolved_Conflicts_Info_Question: ' findest du die Timetable Anleitung. Für weitere Fragen kannst du uns gerne unter ',
        App_Info_Unresolved_Conflicts_Info_End: ' kontaktieren.',
        App_Info_Contact_Billing: 'Billing@metafinanz.de',
        App_Info_Teach_Bubble_Title: 'Wilkomen zum timetable 2.0',
        App_Info_Teach_Bubble_Message: 'Um Zeit zu buchen klicken sie auf einen Projekt!',
        App_Info_Download_Time_Entries: 'Hier können Sie ihre Leistungserfassungen herunterladen. Bitte wählen Sie einen Zeitraum.',
        App_Info_Dropdown_Projects_No_Selection: 'You have to select at least one project from the multi-select dropdown.',
        App_Info_No_Booked_Times: 'Keine Leistungen für den Zeitraum %{timePeriod} erfasst',
        App_Info_Maintenance_Hours: 'Stunden',
        App_Info_Maintenance_Minutes: 'Minuten',
        App_Info_Maintenance_Seconds: 'Sekunden',
        App_Info_Maintenance_Text: 'Timetable ist wieder verfügbar in',
        App_Info_Activity_Templates_Page_Create: `Sie können mehrere Aktivitätsvorlagen erstellen, die jeweils ihre eigenen einzigartigen Einstellungen haben. Diese Vorlagen vereinfachen die Erstellung zukünftiger Aktivitäten, indem sie wesentliche Details vorausfüllen.`,
        App_Info_Activity_Templates_Page_Create_Steps: `1.Aktivitätsvorlagenname: Weisen Sie Ihrer Vorlage einen Namen zu. Dieser Name wird im Dropdown-Menü angezeigt, wenn Sie eine neue Aktivität erstellen, und hilft Ihnen, die richtige Vorlage schnell zu identifizieren.
        2.Aktivitätsbeschreibung: Geben Sie eine Beschreibung für Ihre Vorlage an. Dies dient als vorausgefüllter Kommentar für Ihre Aktivität und gibt Ihnen einen Vorsprung bei der Beschreibung des Zwecks oder der Art der Aktivität.
        3.Dauer: Legen Sie eine Standarddauer für die Aktivität fest. Diese Dauer wird automatisch zu Ihrer Aktivität hinzugefügt, was Ihnen Zeit spart und Konsistenz bei der Planung ähnlicher Aktivitäten gewährleistet.`,
        App_Info_Activity_Templates_Page_How_To : `Verwendung einer Vorlage: Wählen Sie beim Erstellen einer neuen Aktivität einfach die gewünschte Vorlage aus dem Dropdown-Menü aus. Die Aktivität wird automatisch mit der Beschreibung und Dauer der Vorlage ausgefüllt, was Ihren Planungsprozess vereinfacht.`,

        //Time Recordings notifications
        App_Notification_TimeRecording_Create_Success: 'Leistungserfassungen erfolgreich angelegt.',
        App_Notification_TimeRecording_Create_Error: 'Fehler beim Anlegen der Leistungserfassungen.',
        App_Notification_TimeRecording_Update_Success: 'Leistungserfassungen wurde erfolgreich aktualisiert.',
        App_Notification_TimeRecording_Update_Error: 'Fehler beim Aktualisieren der Leistungserfassungen.',
        App_Notification_TimeRecording_Delete_Success: 'Leistungserfassungen wurde erfolgreich gelöscht.',
        App_Notification_TimeRecording_Delete_Error: 'Fehler beim Löschen der Leistungserfassungen.',
        App_Notification_TimeRecording_Error_MissingComment: 'Kommentar in der Leistungserfassungen muss angegeben werden.',
        App_Notification_TimeRecording_Error_Invoice_Non_Bookable: 'Deine Zeitbuchung konnte nicht ins metaBusinessSystem sychronisiert werden, da auf diese Projekt Leistungserfassungen nicht mehr gebucht werden darf wegen Buchungsabschluss. Bitte wende dich an die Kollegen des ProjektOffice.',
        App_Notification_TimeRecording_Error_Non_Bookable: 'Deine Zeitbuchung konnte nicht ins metaBusinessSystem sychronisiert werden, da auf diese Projekt Leistungserfassungen nicht mehr gebucht werden darf. Bitte wende dich an die Kollegen des ProjektOffice.',
        App_Notification_TimeRecording_Copy_Success: 'Leistungserfassungen erfolgreich kopiert.',
        App_Notification_TimeRecording_Copy_Error: 'Fehler beim kopieren der Leistungserfassungen.',
        App_Notification_TimeRecording_Copy_NotEnoughTime: 'Fehler beim Kopieren deiner Leistungserfassungen',

        //User Project notifications
        App_Notification_Project_Create_Success: 'Privates Projekt erfolgreich angelegt.',
        App_Notification_Project_Create_Error: 'Fehler beim Anlegen des privaten Projektes.',
        App_Notification_Project_Update_Success: 'Projekt erfolgreich aktualisiert.',
        App_Notification_Project_Update_Error: 'Fehler beim Aktualisieren des Projektes.',
        App_Notification_Project_Delete_Success: 'Das Projekt wurde erfolgreich gelöscht!',
        App_Notification_Project_Delete_Error: 'Fehler beim Löschen des privaten Projektes.',
        App_Notification_Project_Error_NoTitle: 'Projekt Titel darf nicht leer sein!',
        App_Notification_Project_Error_EmptyStartDate: 'Start Datum darf nich leer sein!',
        App_Notification_Project_Error_EmptyEndDate: 'Ende Datum darf nicht leer sein!',
        App_Notification_Project_Error_InvalidStartDate: 'Start Datum muss kleiner sein als Ende Datum!',
        
        //Project Groups notifications
        App_Notification_Project_Group_Create_Success: 'Projekt Gruppe erfolgreich angelegt',
        App_Notification_Project_Group_Create_Error: 'Fehler beim Anlegen des Projekt Gruppe!',
        App_Notification_Project_Group_Update_Success: 'Projekt Gruppe erfolgreich aktualisiert',
        App_Notification_Project_Group_Update_Error: 'Fehler beim Aktualisieren des ProjektGruppe!',
        App_Notification_Project_Group_Delete_Success: 'Das Projekt Gruppe wurde erfolgreich gelöscht!',
        App_Notification_Project_Group_Delete_Error: 'Fehler beim Löschen des ProjektGruppe!',
        App_Notification_Project_Group_Error_NoTitle: 'Projekt Titel darf nicht leer sein!',

        //Skills notifications
        App_Notification_Skill_Create_Success: 'Aktivitätsvorlage erfolgreich angelegt.',
        App_Notification_Skill_Create_Error: 'Fehler beim Anlegen der Aktivitätsvorlage.',
        App_Notification_Skill_Update_Success: 'Aktivitätsvorlage erfolgreich aktualisiert.',
        App_Notification_Skill_Update_Error: 'Fehler beim Aktualisieren der Aktivitätsvorlage.',
        App_Notification_Skill_Delete_Success: 'Aktivitätsvorlage erfolgreich gelöscht.',
        App_Notification_Skill_Delete_Error: 'Fehler beim Löschen der Aktivitätsvorlagee.',
        App_Notification_Skill_Error_MissingName: 'Aktivitätsvorlagesname darf nicht leer bleiben.',
        App_Notification_Skill_Error_MissingComment: 'Aktivitätsvorlagekommentar darf nich leer bleiben.',

        //Booking Closure
        App_Notification_BookingClosure_Success: 'Buchungsabschluss erfolgreich erstellt!',
        App_Notification_BookingClosure_Error: 'Beim Festlegen des Buchungsabschlusses ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        App_Notification_BookingClosure_Reset_Success: 'Buchungsabschluss erfolgreich aktualisiert!',
        App_Notification_BookingClosure_Reset_Error: 'Beim Aktualisieren des Buchungsabschlusses ist ein Fehler aufgetreten. Bitte versuche es erneut.',

        //Ribbon notifications
        App_Notification_Ribbon_Language_Update_Error: 'Fehler beim Aktualisieren der Spracheinstellung.',
        App_Notification_Ribbon_BookingFormat_Update_Error: 'Fehler beim Aktualisieren des Buchungsformates.',

        //Export Time notifications
        App_Notification_Export_InvalidDate: 'Bitte wählen Sie einen gültigen Zeitraum!',
        App_Notification_Export_NoRecordings: 'Für den ausgewählten Zeitraum wurden keine Leistungserfassungen gefunden.',

        App_Notification_Error_StartDateNotInTisMonth: 'Start Datum ist nicht im aktuellen Monat!',
        App_Notification_Error_EndDateNotInTisMonth: 'Ende Datum ist nicht im aktuellen Monat!',

        App_Copy_Text: 'Wähle die Tage für den die Leistungserfassung mit dem Kommentar %{comment} auf dem Arbeitspaket %{project} kopiert werden soll.',
        App_Copy_Error_Message: 'Dein Arbeitspaket verfügt nicht über ausreichend Budget, um die Zeiterfassung für alle von dir ausgewählten Tage zu kopieren.Die Zeiterfassung wurde nicht für folgenden Tage kopiert: %{datesOverflow}'
    }
};