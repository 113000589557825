import './App.css';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useAppSelector } from './Redux';
import { RibbonBar } from './Components/Ribbon/RibbonBarComponent';
import { setLocale, setTranslations } from 'react-i18nify';
import { translations } from "./i18n/translations"
import { Navigation } from './Components/Navigation/NavigationComponent';
import { BrowserRouter } from "react-router";
import { Pages } from './Components/Navigation/PagesComponent';
import { DateRangeType } from '@fluentui/react-calendar-compat';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, updateLoadingState, setUserProfile, useLazyFetchUserProfileQuery, updateDateRangeType, setErrorState } from './Redux';
import { useNavigate, useMatch, useResolvedPath } from 'react-router-dom';
import { loginRequest } from "./Auth/authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useEffect } from 'react';
import { NotificationMessages } from './Components/Notifications/NotificationMessagesComponent';
import { MaintenanceUpdatePanel } from './Components/Maintenance/MaintenanceUpdatePanelComponent';
import { UserSettingsPanelComponent } from './Components/UserSettingsPanel/UserSettingsPanelComponent';
import { FluentProvider } from '@fluentui/react-components';
import { metaTheme } from "./Utils/fluentTheme";
import { MonthNavigationArrow } from './Components/Calendar/MonthNavigationArrowComponent';
import { TotalBookedHours } from './Components/Total/TotalBookedHoursComponent';
import { CalloutDatePicker } from './Components/Calendar/CalloutDatePickerComponent';
import { Footer } from './Components/Footer/FooterContainerComponent';
import { ActivityTemplateModalComponent } from './Components/ActivityTemplates/ActivityTemplateModalComponent';
import { ProjectEditPanel } from './Components/Projects/ProjectEditPanel';
import TimeEntryCopyPanelComponent from './Components/TimeEntry/TimeEntryCopyPanelComponent';
import { SnowParticles } from './Components/Ribbon/SnowParticles';
import { FunOverlay } from './Components/Fun/FunOverlay';
import { GlobalNotifications } from './Components/Notifications/GlobalNotificationComponent';
import { GlobalNotificationDocument } from './Components/Notifications/GlobalNotificationDocument';

/* translations */
setTranslations(translations);

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  setLocale(`Lang_1033`, false);
  const isNavigationPanelOpen = useAppSelector((state) => state.userProfile.isNavigationPanelOpen);
  const hasGlobalnotification = useAppSelector(s => s.administration.hasGlobalnotification);

  return (
    <MsalProvider instance={pca}>
      <BrowserRouter basename='/'>
        <FluentProvider theme={metaTheme}>
          <div className='overflow-x-hidden bg-neutral w-full min-h-screen cursor-fx-target'>
            <AuthenticationComponent />
            <RibbonBar />
            <div className='flex flex-row'>
              <Navigation />
              <div className={`${isNavigationPanelOpen ? 'lg:pl-64' : 'lg:pl-16'} top-20 left-0 w-full fixed z-20`}>
                <GlobalNotifications />
                <div className={`bg-neutral fixed shadow-lg w-full h-18 lg:invisible py-1`}>
                  <div className="mx-auto flex flex-row justify-center space-x-20 lg:hidden flex-wrap">
                    <MonthNavigationArrow past />
                    <CalloutDatePicker />
                    <MonthNavigationArrow />
                  </div>
                  <div className="flex lg:hidden w-full text-center justify-center">
                    <TotalBookedHours />
                  </div>
                </div>
              </div>
              <div className='top-24 z-[120] w-full absolute'>
                <NotificationMessages />
              </div>
              <div className={`${hasGlobalnotification ? 'mt-56 lg:mt-40' : 'mt-36'} mb-1 lg:mb-1 lg:mt-32 lg:pr-8 w-full flex flex-row items-start space-x-3 ${isNavigationPanelOpen ? 'lg:pl-72' : 'lg:pl-24'}`}>
                <Pages />
              </div>
            </div>
          </div>

          <GlobalNotificationDocument />
          <ProjectEditPanel />
          <MaintenanceUpdatePanel />
          <UserSettingsPanelComponent />
          <ActivityTemplateModalComponent />
          <TimeEntryCopyPanelComponent />
          <Footer />
          <SnowParticles />
          <FunOverlay />
        </FluentProvider>
      </BrowserRouter>
    </MsalProvider>
  );
}

function AuthenticationComponent() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  var isAuthenticated = useIsAuthenticated();
  let resolved = useResolvedPath('/dashboard');
  let isDashboardPathMatch = useMatch({ path: resolved && resolved.pathname ? resolved.pathname : "", end: true });
  const [fetchUserProfile, { data: userData, isSuccess, isError, error }] = useLazyFetchUserProfileQuery();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(e => console.error(e));
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      dispatch(updateLoadingState(true));
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response: AuthenticationResult) => {
        setLoginDetails(response);
      }, (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          instance.acquireTokenPopup(loginRequest).then((response) => {
            setLoginDetails(response);
          });
        }
      });
    }
  }, [isAuthenticated, accounts, inProgress]);

  const setLoginDetails = (authDetails: AuthenticationResult) => {
    localStorage.setItem("accessToken", authDetails.accessToken);
    localStorage.setItem("userIdentifier", authDetails.uniqueId);

    fetchUserProfile();
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(updateLoadingState(false));
      dispatch(setUserProfile(userData));
      dispatch(updateDateRangeType(userData?.bookingFormat === 0 ? DateRangeType.Week : DateRangeType.Month));
      if (!isDashboardPathMatch) {
        navigate('/dashboard');
      }
    }
    else if (isError) {
      dispatch(updateLoadingState(false));
      var err = error as FetchBaseQueryError;
      if (err.status == 404) {
        navigate('/nouserprofile');
      } else {
        dispatch(setErrorState());
        navigate('/error');
      }
    }
  }, [isSuccess, isError]);

  return <></>;
}

export default App;
