import { NavigationButton } from "../Buttons/NavigationButton"
import { setHasReadChangelog, useAppDispatch, useAppSelector, useUpdateHasReadChangelogMutation } from "../../Redux";
import { useEffect, useState } from "react";
import { History28Regular } from '@fluentui/react-icons'

interface IChangelogNavigationButtonProps {
    small?: boolean
}

export const ChangelogNavigationButton = (props: IChangelogNavigationButtonProps) => {
    const { small } = { ...props };

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const [isCoachmarkVisible, setIsCoachmarkVisible] = useState(false);
    const [updateHasReadChangelog] = useUpdateHasReadChangelogMutation();

    useEffect(() => {
        if (userProfile?.id && !userProfile.hasSeenChangelog) {
            setIsCoachmarkVisible(true);
        }
    }, [userProfile]);

    const handleChangelogClick = () => {
        if (!userProfile?.hasSeenChangelog) {
            updateHasReadChangelog(true);
            dispatch(setHasReadChangelog(true));
            setIsCoachmarkVisible(false);
        }
    }

    return (<>
        <NavigationButton buttonText='App_Button_Changelog' icon={<History28Regular className="stroke-0 stroke-primary w-5 h-5" />} navigationPath='/changelog' callback={handleChangelogClick} showCoachMark={isCoachmarkVisible} small={small} />
    </>)
}