import { useEffect } from "react";
import { translate } from "react-i18nify";
import { IActivityTemplate, IErrorResponse } from "../../Models";
import { hideAddActivityTemplateDialog, showAddActivityTemplateDialog, showNotificationWithTimeout, updateLoadingState, useAppDispatch, useAppSelector, useDeleteActivityTemplateMutation, usePutActivityTemplateMutation } from "../../Redux";
import { useSortable } from "@dnd-kit/sortable";
import { transformMinutesToBookedTime } from "../../Utils/Utils";
import { DragHandle } from "../Buttons/DragHandle";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

interface IActivityTemplatesRow {
    activityTemplate: IActivityTemplate
}

export const ActivityTemplatesRow = (props: IActivityTemplatesRow) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        isDragging,
        transform,
        transition,
    } = useSortable({
        id: props.activityTemplate.order
    });

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);

    const [updateActivityTemplate, { isLoading: isPutLoading, isSuccess: isPutSuccess, isError: isPutError, error: putError }] = usePutActivityTemplateMutation();
    const [deleteActivityTemplate, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError }] = useDeleteActivityTemplateMutation();

    useEffect(() => {
        if (isPutLoading || isDeleteLoading) {
            dispatch(updateLoadingState(true));
        }
    }, [isPutLoading, isDeleteLoading]);

    useEffect(() => {
        if (isPutSuccess) {
            dispatch(hideAddActivityTemplateDialog());
            displayNotification('App_Notification_Skill_Update_Success');
        }

        if (isDeleteSuccess) {
            displayNotification('App_Notification_Skill_Delete_Success');
        }

        dispatch(updateLoadingState(false));
    }, [isPutSuccess, isDeleteSuccess]);

    useEffect(() => {
        if (isPutError && putError) {
            const parsedError: IErrorResponse = { ...putError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        if (isDeleteError && deleteError) {
            const parsedDeleteError: IErrorResponse = { ...deleteError };
            displayNotification(typeof parsedDeleteError.data == 'string' ? parsedDeleteError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPutError, putError, isDeleteError, deleteError]);

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    const onEditClick = () => {
        dispatch(showAddActivityTemplateDialog({ confirm: saveActivityTemplate, activityTemplate: props.activityTemplate }))
    }

    const saveActivityTemplate = async (activityTemplateToUpdate: IActivityTemplate) => {
        activityTemplateToUpdate.id = props.activityTemplate.id;
        activityTemplateToUpdate.userProfile = userProfile
        updateActivityTemplate(activityTemplateToUpdate);
    }

    const onDeleteClick = () => {
        deleteActivityTemplate(props.activityTemplate.id);
    }

    return (
        <div className={`w-full flex flex-row items-center space-x-1 p-2 ${isDragging ? 'blur' : 'blur-none'}`} ref={setNodeRef}>
            <div className="w-1/3 lg:w-1/6 truncate">
                <span {...attributes} {...listeners} className={`px-2 ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}><DragHandle /></span>
                {props.activityTemplate.title}
            </div>
            <div className="w-4/6 truncate">
                {props.activityTemplate.description}
            </div>
            <div className="hidden lg:block w-1/12 truncate select-none">
                {transformMinutesToBookedTime(props.activityTemplate.defaultBookedTime)}
            </div>
            <div className="w-2/12 lg:w-1/12 text-right flex flex-row justify-center">
                <PencilIcon className='cursor-pointer stroke-1 stroke-primary h-4 w-5' onClick={onEditClick} />
                <TrashIcon className='ml-2 cursor-pointer stroke-1 stroke-primary h-4 w-5' onClick={onDeleteClick} />
            </div>
        </div>
    )
}