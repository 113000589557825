import { configureStore } from '@reduxjs/toolkit';
import projectsReducer, { userProjectsApi } from './projectsSlice'
import userProfileReducer, { userProfileApi } from './userProfileSlice'
import activityTemplatesReducer, { activityTemplatesApi } from './activityTemplatesSlice'
import administrationReducer, { administrationApi } from './administrationSlice';
import timeEntriesReducer, { timeRecordsApi } from './timeRecordsSlice';
import calendarReducer from './calendarSlice'
import loadingReducer from './loadingSlice'
import projectGroupReducer, { projectGroupsApi } from './projectsGroupSlice'
import { countryApi } from './countrySlice'
import publicHolidayReducer, { publicHolidayApi } from './publicHolidaySlice'
import notificationReducer from './notificationsSlice';
import thunkMiddleware from 'redux-thunk'

export const store = configureStore({
    reducer: {
        userProfile: userProfileReducer,
        projects: projectsReducer,
        projectGroups: projectGroupReducer,
        timeEntries: timeEntriesReducer,
        calendar: calendarReducer,
        loading: loadingReducer,
        activityTemplates: activityTemplatesReducer,
        notification: notificationReducer,
        administration: administrationReducer,
        publicHoliday: publicHolidayReducer,

        [userProjectsApi.reducerPath]: userProjectsApi.reducer,
        [userProfileApi.reducerPath]: userProfileApi.reducer,
        [timeRecordsApi.reducerPath]: timeRecordsApi.reducer,
        [activityTemplatesApi.reducerPath]: activityTemplatesApi.reducer,
        [administrationApi.reducerPath]: administrationApi.reducer,
        [countryApi.reducerPath]: countryApi.reducer,
        [publicHolidayApi.reducerPath]: publicHolidayApi.reducer,
        [projectGroupsApi.reducerPath]: projectGroupsApi.reducer
    },
    middleware: getDefaultMiddleware => {
         return getDefaultMiddleware({ serializableCheck: false })
            .concat(userProfileApi.middleware)
            .concat(timeRecordsApi.middleware)
            .concat(userProjectsApi.middleware)
            .concat(activityTemplatesApi.middleware)
            .concat(administrationApi.middleware)
            .concat(countryApi.middleware)
            .concat(publicHolidayApi.middleware)
            .concat(projectGroupsApi.middleware)
            // .concat(thunkMiddleware);
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch