import { Logo } from './LogoComponent';
import { LanguageSwitch } from '../Language/LanguageComponent';

export const RibbonBar = () => {
    return (<>
        <div className="shadow-md bg-primary h-20 fixed top-0 left-0 z-[101] m-0 w-full flex p-0 items-center">
            <div className="hidden lg:flex absolute top-[-192px] right-[100px] z-[1011] w-40 h-96 bg-[url('metaLines.svg')] bg-cover bg-right bg-no-repeat rotate-90"></div>
            <div className="flex lg:justify-center w-screen">
                <Logo />
            </div>
        </div>
        <div className='fixed lg:hidden top-0 right-2 lg:right-0 z-[108] h-20 flex flex-row flex-grow flex-nowrap items-center justify-end space-x-3'>
            <div>
                <LanguageSwitch />
            </div>
        </div>
    </>
    );
}