import { useEffect, useState } from "react";
import { hideAddActivityTemplateDialog, setActivityTemplates, showAddActivityTemplateDialog, showNotificationWithTimeout, updateLoadingState, useAppDispatch, useAppSelector, useFetchActivityTemplatesQuery, usePostActivityTemplateMutation, useUpdateActivityTemplateOrderMutation } from "../../Redux";
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { IActivityTemplate, IErrorResponse } from "../../Models";
import { DraggedActivityTemplateRow } from "./DraggedActivityTemplateRow";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Translate, translate } from "react-i18nify";
import { ActivityTemplatesRow } from "./ActivityTemplatesRow";

export const ActivityTemplatesContainer = () => {
    const dispatch = useAppDispatch();
    var userProfile = useAppSelector((state) => state.userProfile.userProfile);

    const { data: activityTemplatesResult } = useFetchActivityTemplatesQuery();
    const [updateActivityTemplateOrder, { }] = useUpdateActivityTemplateOrderMutation();
    const [postActivityTemplate, { isLoading: isPostLoading, isSuccess: isPostSuccess, isError: isPostError, error: postError }] = usePostActivityTemplateMutation();

    const [items, setItems] = useState<any>([]);
    const [bookingComments, setBookingCommentsValue] = useState<IActivityTemplate[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [draggedBookingComment, setDraggedBookingComment] = useState<IActivityTemplate>();
    const [showMore, setShowMore] = useState(false);

    const sensors = useSensors(
        // useSensor(PointerSensor),
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                delay: 100,
                tolerance: 5,
            },
        }),
        useSensor(TouchSensor),
    );

    useEffect(() => {
        if (activityTemplatesResult && activityTemplatesResult.length > 0) {
            dispatch(setActivityTemplates(activityTemplatesResult));
            setBookingCommentsValue(activityTemplatesResult);
            setItems(activityTemplatesResult.map((b, i) => b.order));
            dispatch(hideAddActivityTemplateDialog());
        }
    }, [activityTemplatesResult]);

    const handleDragEnd = function (ev: DragEndEvent) {
        const { active, over } = ev;

        setIsDragging(false);
        setDraggedBookingComment(undefined);

        if (over && active.id !== over?.id) {
            setItems((items: unknown[]) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over?.id);

                return arrayMove(items, oldIndex, newIndex);
            });

            if (bookingComments && bookingComments.length > 0) {
                var activeItem = bookingComments.filter(b => b.order == active.id)[0];
                var overItem = bookingComments.filter(b => b.order == over.id)[0];
                var oldIndex = bookingComments.indexOf(activeItem);
                var newIndex = bookingComments.indexOf(overItem);

                let newBookingCommentsOrder = arrayMove(bookingComments, oldIndex, newIndex)
                setBookingCommentsValue(newBookingCommentsOrder);
                updateActivityTemplateOrder(newBookingCommentsOrder.map(b => b.id));
            }
        }
    }

    const onDragStart = function (ev: DragStartEvent) {
        if (ev.active && ev.active.id) {
            setIsDragging(true);
            setDraggedBookingComment(bookingComments?.filter(b => b.order == ev.active.id)[0]);
        }
    }

    const onNewClick = () => {
        dispatch(showAddActivityTemplateDialog({ confirm: confirmAdd }))
    }

    useEffect(() => {
        if (isPostLoading) {
            dispatch(updateLoadingState(true));
        }
    }, [isPostLoading]);

    useEffect(() => {
        if (isPostSuccess) {
            dispatch(hideAddActivityTemplateDialog());
            displayNotification('App_Notification_Skill_Create_Success');
        }

        if (isPostError && postError) {
            const parsedError: IErrorResponse = { ...postError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPostSuccess, isPostError, postError]);

    const confirmAdd = (confirmResponse: IActivityTemplate) => {
        confirmResponse.userProfile = userProfile;
        confirmResponse.userProfile_Id = userProfile?.id;

        postActivityTemplate(confirmResponse);
    }

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    return (<>
        <div className="w-full flex flex-col space-y-3 mb-12 lg:mx-auto lg:w-11/12 lg:shadow-md lg:py-3 lg:px-2 lg:mt-10">
            <div className="flex w-full">
                <div className="w-full px-1">
                    <h1 className="text-base font-semibold leading-6 text-primary"><Translate value='App_Button_Activity_Templates' /></h1>
                    <p className="mt-2 text-sm text-black whitespace-nowrap truncate lg:whitespace-pre-line">
                        <Translate value="App_Info_Activity_Templates_Page_Create" />
                    </p>
                    <p className={`${showMore ? 'block' : 'hidden'} lg:block pl-5 whitespace-pre-line text-sm text-black`}>
                        <Translate value="App_Info_Activity_Templates_Page_Create_Steps" />
                    </p>
                    <p className={`${showMore ? 'block' : 'hidden'} lg:block mt-2 text-sm text-black`}>
                        <Translate value="App_Info_Activity_Templates_Page_How_To" />
                    </p>
                    <button className="flex lg:hidden text-sm justify-start p-[2px] my-2 items-center text-primary cursor-pointer bg-neutral font-semibold" onClick={() => setShowMore(!showMore)}>
                        {showMore ? '...Less' : 'More...'}
                    </button>
                </div>
            </div>
            <div className="flex justify-end w-full lg:text-sm rounded-sm items-center space-x-2 text-primary cursor-pointer p-2">
                <div onClick={onNewClick} className="font-semibold flex flex-row items-center bg-primary text-tertiary px-3 py-2 hover:bg-primary/70">
                    <PlusIcon className='w-4 h-4 stroke-[2]' />
                    <Translate value='App_Button_Add_New' />
                </div>
            </div>
            <div className="divide-y divide-primary/10">
                <div className={`w-full flex flex-row items-center bg-primary text-tertiary h-10 px-2 text-sm font-semibold`} >
                    <div className="w-1/3 lg:w-1/6 truncate pl-4"><Translate value='App_Forms_Activity_Template_Name' /></div>
                    <div className="w-4/6 truncate"><Translate value='App_Forms_Activity_Template_Description' /></div>
                    <div className="hidden lg:block w-1/12 truncate select-none"><Translate value="App_Label_Duration" /></div>
                    <div className="w-2/12 lg:w-1/12 text-right"></div>
                </div>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={onDragStart} onDragEnd={handleDragEnd}>
                    <SortableContext items={items} strategy={verticalListSortingStrategy}>
                        {
                            bookingComments?.map((b, i) =>
                                <ActivityTemplatesRow key={b.order} activityTemplate={b} />
                            )
                        }
                    </SortableContext>
                    {isDragging && draggedBookingComment &&
                        <DragOverlay>
                            <DraggedActivityTemplateRow bookingComment={draggedBookingComment} />
                        </DragOverlay>
                    }
                </DndContext>
            </div>
        </div>
    </>)
}