import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultCalendarStrings, CalendarStrings, DateRangeType } from '@fluentui/react-calendar-compat';
import { DateTime } from 'luxon';

const germanCalendarStrings: CalendarStrings = {
    goToToday:  "Heute",
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli" ,"August", "September", "Oktober", "November", "Dezember"],
    shortMonths: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul" ,"Aug", "Sep", "Okt", "Nov", "Dez"],    
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    shortDays: ["S", "M", "D", "M", "D", "F", "S"]
}

interface ICalendarState {
    selectedDate: Date,
    dateRange: Date[],
    dateRangeType: DateRangeType,
    calendarStrings: CalendarStrings
}

const initialState: ICalendarState = {
    selectedDate: new Date(),
    dateRange: [],
    dateRangeType: DateRangeType.Week,
    calendarStrings: defaultCalendarStrings
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        updateDateRange: (state, action: PayloadAction<Date[]>) => {
            state.dateRange.length = 0;            
            state.dateRange.push(action.payload[0]);
            state.dateRange.push(action.payload[action.payload.length - 1]);

            let firstDate = action.payload[0];
            let now = new Date();

            if(state.dateRangeType === DateRangeType.Month)
            {
                state.selectedDate = firstDate.getMonth() === now.getMonth() ? now : action.payload[0];
            } else {                
                state.selectedDate = DateTime.fromJSDate(firstDate).weekNumber === DateTime.fromJSDate(now).weekNumber ? now : action.payload[0];
            }
        },
        updateDateRangeType: (state, action: PayloadAction<DateRangeType>) => {
            state.dateRangeType = action.payload;
        },
        setCalendarStrings: (state, defaultStrings: PayloadAction<boolean>) => {
            state.calendarStrings = defaultStrings.payload ? defaultCalendarStrings : germanCalendarStrings;
        }
    }
});

export const { updateDateRange, updateDateRangeType, setCalendarStrings } = calendarSlice.actions;

export default calendarSlice.reducer;