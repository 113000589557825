import { useAppSelector } from '../../Redux'
import { IProjectGroup, IUserProject } from '../../Models';
import { useMemo, useState } from 'react';
import { Divider } from '../Divider/DividerComponent';
import { ProjectsList } from '../Projects/ProjectsListComponent';
import { ChevronDownRegular, ChevronRightRegular} from '@fluentui/react-icons'

interface IProjectGroupHeaderComponentProps {
    projectGroup: IProjectGroup,
    autoExpand: boolean
}

export const ProjectGroupComponent = (props: IProjectGroupHeaderComponentProps) => {
    const { projectGroup, autoExpand } = {...props}

    const [isExpanded, setIsExpanded] = useState<boolean>(autoExpand);
    const [projects, setProjects] = useState<IUserProject[]>([]);
    const [projectCount, setProjectCount] = useState<number>(0);

    const projectsList = useAppSelector((state) => state.projects.projectsList);
    
    useMemo(() => {
        setProjects(projectsList.filter(p => p.projectGroup_Id == projectGroup.id));
        setProjectCount(projectsList.filter(p => p.projectGroup_Id == props.projectGroup.id).length);
    }, [projectsList]);

    return (<>
        {projectCount > 0 && <Divider left iconLeft={isExpanded ? <ChevronDownRegular className='h-6 w-6' /> : <ChevronRightRegular className='h-6 w-6' />} text={projectGroup.title} clickable onClick={() => setIsExpanded(!isExpanded)} />}
        {isExpanded && <ProjectsList projects={projects} />}
    </>)
}