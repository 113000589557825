import { useEffect, useState } from 'react';
import { mapNumber } from '../../Utils/Utils';
import { CountdownCircle } from './CountdownCircleComponent';
import { DateTime } from 'luxon';
import { setIsMaintenance, useAppDispatch, useAppSelector } from '../../Redux';
import { useNavigate } from 'react-router-dom';
import { Translate } from 'react-i18nify';

export const CountdownTimer = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const endDate = useAppSelector(s => s.administration.maintenanceEndDate);

    const [state, setState] = useState<{ hours: number, minutes: number, seconds: number }>({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        if (endDate) {
            const timer = setInterval(() => {
                const then = DateTime.fromJSDate(new Date(endDate));
                const now = DateTime.now();

                const timeSpan = then.diff(now, ['hours', 'minutes', 'seconds']).toObject();

                const hours = timeSpan.hours ?? 0;
                const minutes = timeSpan.minutes ?? 0;
                const seconds = Math.ceil(timeSpan.seconds ?? 0);
                if (hours <= 0 && minutes <= 0 && seconds <= 0) {
                    dispatch(setIsMaintenance(false));
                    window.location.reload();
                }

                setState({ hours, minutes, seconds });
            }, 1000);

            return () => {
                clearInterval(timer);
            }
        }
    }, [endDate]);

    const { hours, minutes, seconds } = state;
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

    return (<>
        <div className='flex items-center justify-center flex-col h-full -mt-32'>
            <div className="flex flex-col text-4xl pb-8 text-primary"><Translate value="App_Info_Maintenance_Text" /></div>
            <div className='flex items-center justify-center flex-wrap space-x-6'>
                <CountdownCircle value={hours} radius={hoursRadius} label={"App_Info_Maintenance_Hours"} />
                <CountdownCircle value={minutes} radius={minutesRadius} label={"App_Info_Maintenance_Minutes"} />
                <CountdownCircle value={seconds} radius={secondsRadius} label={"App_Info_Maintenance_Seconds"} />
            </div>
        </div>
    </>)
}