import { Divider } from "../Divider/DividerComponent"
import { changelogList } from '../../changelog'
import { IChangelog } from "../../Models"
import { ChangelogRow } from "./ChangelogRow"
import { useEffect } from "react"

export const ChangelogPage = () => {
    let changesList: IChangelog[] = [];
    Object.assign(changesList, changelogList);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<div className="w-full min-h-screen px-10 lg:px-20 lg:mt-10 flex flex-col space-y-3">
        {
            changesList.map((t, i) => [<ChangelogRow key={t.versionNumber} change={t} />, <Divider />])
        }
    </div>)
}