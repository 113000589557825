import { DateRangeType } from '@fluentui/react-calendar-compat';
import { useState, useEffect } from 'react';
import { CalendarFormatButton } from '../Buttons/CalendarFormatButton';
import { useAppDispatch, useAppSelector, updateDateRangeType, useUpdateBookingFormatMutation } from '../../Redux';

export const DateRangeTypeSelector = () => {
    const dispatch = useAppDispatch();
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);

    const [isWeekSelectionActive, setIsWeekSelectionActive] = useState<boolean>(true);
    const [updateFormat] = useUpdateBookingFormatMutation();

    const switchCalendarType = (calendarTypeValue: number) => {
        var newType = calendarTypeValue === DateRangeType.Week ? DateRangeType.Week : DateRangeType.Month;
        if (newType != dateRangeType) {
            updateFormat(calendarTypeValue);
            dispatch(updateDateRangeType(calendarTypeValue === DateRangeType.Week ? DateRangeType.Week : DateRangeType.Month));
            setIsWeekSelectionActive(!isWeekSelectionActive);
        }
    }

    useEffect(() => {
        setIsWeekSelectionActive(dateRangeType == DateRangeType.Week);
    }, [dateRangeType])

    return (<div className='hidden lg:flex flex-row space-x-1 w-full justify-around'>
        <CalendarFormatButton buttonText='App_Button_Calendar_Week' calendarTypeValue={DateRangeType.Week} isActive={isWeekSelectionActive} callbak={switchCalendarType} />
        <CalendarFormatButton buttonText='App_Button_Calendar_Month' calendarTypeValue={DateRangeType.Month} isActive={!isWeekSelectionActive} callbak={switchCalendarType} />
    </div>)
}