import { Combobox, Option, useId, } from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";
import { MapRegular } from "@fluentui/react-icons";
import { setPublicHolidays, setUserProfile, updateLoadingState, useAppDispatch, useAppSelector, useLazyFetchCountriesQuery, useLazyFetchPublicHolidaysQuery, useUpdateCountryMutation } from "../../Redux";
import { InfoBubble } from "../Info/InfoBubble";
import { Divider } from "../Divider/DividerComponent";
import { IUserProfile } from "../../Models";

export const CountryPicker = () => {
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const userProfile = useAppSelector(s => s.userProfile.userProfile);
    const isNavigationPanelOpen = useAppSelector((state) => state.userProfile.isNavigationPanelOpen);
    const dropdownId = useId('ddnCountryPicker');
    const targetContainer = useRef<HTMLDivElement>(null);

    const [selectedKey, setSelectedKey] = useState<number>();
    const [comboBoxValue, setComboBoxValue] = useState('');
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [showBubble, setShowBubble] = useState<boolean>(false);

    const [fetchCountries, { data: countryResult, isSuccess }] = useLazyFetchCountriesQuery();
    const [getPublicHolidays, { data: publicHolidaysResult, isSuccess: isRetrieveSuccess }] = useLazyFetchPublicHolidaysQuery();
    const [updateCountry, { isSuccess: isUpdateSuccess, isLoading }] = useUpdateCountryMutation();

    useEffect(() => {
        if (countryResult && countryResult?.length > 0) {
            setTimeout(() => {
                var filterResult = countryResult.filter(t => t.id == userProfile?.country_Id)[0].countryName;
                setSelectedKey(userProfile?.country_Id);
                setSelectedValue(filterResult);
                setComboBoxValue(filterResult);
                getPublicHolidays({ countryId: userProfile?.country_Id as number, from: dateRange[0], until: dateRange[1] });
            }, 1000)
        }
    }, [countryResult])

    useEffect(() => {
        if (isLoading) {
            dispatch(updateLoadingState(true));
        }

        if (isUpdateSuccess) {
            dispatch(updateLoadingState(false));
            getPublicHolidays({ countryId: selectedKey as number, from: dateRange[0], until: dateRange[1] });
        }
    }, [isUpdateSuccess, isLoading])

    useEffect(() => {
        if (isRetrieveSuccess && publicHolidaysResult) {
            dispatch(setPublicHolidays(publicHolidaysResult));
        }
    }, [isRetrieveSuccess, publicHolidaysResult])

    useEffect(() => {
        if (userProfile) {
            if (userProfile.country_Id && userProfile.country_Id > 0) {
                setShowBubble(false);
            } else {
                setShowBubble(true);
            }

            fetchCountries();
        }
    }, [userProfile]);

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
        console.log(data);
        if (data) {
            setSelectedKey(Number(data.optionValue));
            let updatedUserProfile = { ...userProfile };
            updatedUserProfile.country_Id = Number(data.optionValue);
            dispatch(setUserProfile(updatedUserProfile as IUserProfile));

            updateCountry(Number(data.optionValue))
            setSelectedValue(data.optionText as string);
            setComboBoxValue(data.optionText as string);
            setShowBubble(false);
        }
        else {
            setSelectedKey(undefined);
        }
    };

    return (<>
        <div ref={targetContainer} className="w-full">
            <Divider text='App_Forms_Country_Picker' translate />
            <div className={`absolute top-36 z-[1010] flex flex-col items-end
                            ${isNavigationPanelOpen ? 'left-73' : 'left-26'}`}>
                <InfoBubble showBubble={showBubble}
                    title='App_Info_Country_Selection_Title'
                    text='App_Info_Country_Selection'
                    onCloseCallback={() => setShowBubble(false)} />
            </div>
            <Combobox
                id={dropdownId}
                onOptionSelect={onOptionSelect}
                value={comboBoxValue}
                selectedOptions={[selectedValue]}
                expandIcon={<MapRegular className="stroke-primary stroke-0" />}
                positioning={"after-top"}
            >
                {countryResult?.map((option) => (
                    <Option key={`${option.id}`} value={`${option.id}`}>
                        {`${option.countryName}${option.county ? ' - ' : ''}${option.county ?? ''}`}
                    </Option>
                ))}
            </Combobox>
        </div>
    </>)
}
