import { useAppDispatch, useAppSelector, setCurrentProjectGroups, useUpdateGroupsOrderMutation, useFetchProjectGroupsQuery } from '../../Redux'
import { IProjectGroup } from '../../Models';
import { useEffect, useState } from 'react';
import { DndContext, DragEndEvent, DragStartEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { ProjectGroupItem } from './ProjectGroupItemComponent';
import { AddItemButton } from '../Buttons/AddItemButton';
import { ProjectGroupEditItem } from './ProjectGroupEditItemComponent';

export const ProjectGroupsList = () => {
    const dispatch = useAppDispatch();

    const dateRange = useAppSelector((state) => state.calendar.dateRange);
    const { data: projectGroupsResult, isSuccess: projectGroupSuccess } = useFetchProjectGroupsQuery(dateRange, { skip: dateRange == null || dateRange.length < 2 });
    const [updateGroupsOrder, {}] = useUpdateGroupsOrderMutation();
    const [items, setItems] = useState<any>([]);
    const [projectGroups, setProjectGroups] = useState<IProjectGroup[]>([]);
    const [showNewProjectGroup, setShowNewProjectGroup] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState(false);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 100,
                tolerance: 5,
            },
        }),
        useSensor(TouchSensor),
    );
    
    useEffect(() => {
        if (projectGroupSuccess && projectGroupsResult) {
            dispatch(setCurrentProjectGroups(projectGroupsResult as IProjectGroup[]))
            setProjectGroups(projectGroupsResult);
            setItems(projectGroupsResult.map((b, i) => b.order));
        }
    }, [projectGroupSuccess, projectGroupsResult]);

    const handleDragEnd = function (event: DragEndEvent): void {
        const { active, over } = event;

        if (over && active.id !== over?.id) {
            setItems((items: unknown[]) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over?.id);

                return arrayMove(items, oldIndex, newIndex);
            });

            if (projectGroups && projectGroups.length > 0) {
                var activeItem = projectGroups.filter(b => b.order == active.id)[0];
                var overItem = projectGroups.filter(b => b.order == over.id)[0];
                var oldIndex = projectGroups.indexOf(activeItem);
                var newIndex = projectGroups.indexOf(overItem);

                let newProjectGroupsOrder = arrayMove(projectGroups, oldIndex, newIndex)
                setProjectGroups(newProjectGroupsOrder);
                dispatch(setCurrentProjectGroups(newProjectGroupsOrder));
                updateGroupsOrder(newProjectGroupsOrder.map(b => b.id));
            }
        }

        setIsDragging(false);
    }

    const handleDragStart = function (ev: DragStartEvent) {
        if (ev.active && ev.active.id) {
            setIsDragging(true);
        }
    }

    return (<>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <SortableContext items={items} strategy={rectSortingStrategy}>
                <div className='flex flex-col w-full mt-1 space-y-1'>
                    {
                        projectGroups && projectGroups.map((p, i) => <ProjectGroupItem key={p.id} projectGroup={p} isDroppable={false} /> )
                    }
                </div>
            </SortableContext>            
        </DndContext>
        {showNewProjectGroup && <ProjectGroupEditItem callback={() => setShowNewProjectGroup(false)}/>}
        {!showNewProjectGroup && !isDragging && <div className='w-full hidden lg:flex justify-center'><AddItemButton onClick={() => setShowNewProjectGroup(true)} /> </div>}
    </>)
}