import { Translate } from "react-i18nify";
import { useAppDispatch, useAppSelector, switchShowUserSettings, usePutUserProfileMutation, setUserProfile } from "../../Redux";
import { Description, Field, Label, Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { NumberInput } from "../Inputs/NumberInput";
import { IUserProfile } from "../../Models";

export const UserSettingsPanelComponent = () => {
    const dispatch = useAppDispatch();
    const displayPanel = useAppSelector(s => s.userProfile.showUserSettingsPanel);
    const userProfile = useAppSelector(s => s.userProfile.userProfile);

    const [updateProfile, { isSuccess, isLoading }] = usePutUserProfileMutation();
    const [isAutoIncrementEnabled, setIsAutoIncrementEnabled] = useState<boolean>(false);
    const [isAutoIncrementSkipHolidays, setIsAutoIncrementSkipHolidays] = useState<boolean>(false);
    const [autoIncrementHoursThreshold, setAutoIncrementHoursThreshold] = useState<number>(8);

    useEffect(() => {
        if (userProfile != null) {
            setIsAutoIncrementEnabled(userProfile.isAutoIncrementEnabled);
            setIsAutoIncrementSkipHolidays(userProfile.autoIncrementSkipHolidays);
            setAutoIncrementHoursThreshold(userProfile.autoIncrementHoursThreshold);
        }
    }, [userProfile])

    useEffect(() => {
        if (isSuccess) {
            let profile = {
                ...userProfile,
                isAutoIncrementEnabled: isAutoIncrementEnabled,
                autoIncrementSkipHolidays: isAutoIncrementSkipHolidays,
                autoIncrementHoursThreshold: autoIncrementHoursThreshold
            };

            dispatch(setUserProfile(profile as IUserProfile));
            panelDismiss();
        }
    }, [isSuccess])

    const panelDismiss = () => {
        dispatch(switchShowUserSettings());
    }

    const onSave = () => {
        let profile = {
            ...userProfile,
            isAutoIncrementEnabled: isAutoIncrementEnabled,
            autoIncrementSkipHolidays: isAutoIncrementSkipHolidays,
            autoIncrementHoursThreshold: autoIncrementHoursThreshold
        };

        updateProfile(profile);
    }

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (<>
        {
            displayPanel &&
            <div className="relative z-[1011]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-neutral bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 my-auto">
                            <div className="pointer-events-auto relative w-screen max-w-lg">
                                <div className="absolute left-full top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                    <button type="button" className="relative rounded-md text-primary hover:text-primary-dark" onClick={panelDismiss}>
                                        <span className="absolute -inset-2.5"></span>
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6 stroke-primary stroke-1" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <h2 className="text-base font-semibold leading-6 text-primary" id="slide-over-title"><Translate value="App_Forms_User_Settings_Panel_Title" /></h2>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="flex flex-col w-full space-y-5 px-5 justify-center mt-10">
                                            <Field className="flex items-center justify-between space-x-5">
                                                <span className="flex flex-grow flex-col">
                                                    <Label as="span" className="text-sm font-medium leading-6 text-primary" passive><Translate value="App_Label_Auto_Increment_Enabled" /></Label>
                                                    <Description as="span" className="text-sm text-gray-dark"><Translate value="App_Label_Description_Auto_Increment_Enabled" /></Description>
                                                </span>
                                                <Switch checked={isAutoIncrementEnabled} onChange={() => setIsAutoIncrementEnabled(!isAutoIncrementEnabled)}
                                                    className={classNames(isAutoIncrementEnabled ? 'bg-primary' : 'bg-gray-dark',
                                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
                                                    )}>
                                                    <span aria-hidden="true"
                                                        className={classNames(
                                                            isAutoIncrementEnabled ? 'translate-x-5' : 'translate-x-0',
                                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )} />
                                                </Switch>
                                            </Field>
                                            <Field className="flex items-center justify-between space-x-5">
                                                <span className="flex flex-grow flex-col">
                                                    <Label as="span" className="text-sm font-medium leading-6 text-primary" passive><Translate value="App_Label_Auto_Increment_Skip_Holidays_Enabled" /></Label>
                                                    <Description as="span" className="text-sm text-gray-dark"><Translate value="App_Label_Description_Auto_Increment_Skip_Holidays_Enabled" /></Description>
                                                </span>
                                                <Switch checked={isAutoIncrementSkipHolidays} onChange={() => setIsAutoIncrementSkipHolidays(!isAutoIncrementSkipHolidays)}
                                                    className={classNames(isAutoIncrementSkipHolidays ? 'bg-primary' : 'bg-gray-dark',
                                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
                                                    )}>
                                                    <span aria-hidden="true"
                                                        className={classNames(
                                                            isAutoIncrementSkipHolidays ? 'translate-x-5' : 'translate-x-0',
                                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )} />
                                                </Switch>
                                            </Field>
                                            <div className="flex flex-col">
                                                <div className="w-full flex flex-row justify-between items-center space-x-5">
                                                    <label htmlFor="hours" className="block text-sm font-medium leading-6 text-primary">Auto Increment Hours Threshold</label>
                                                    <NumberInput min={1} max={23} step={1} onChange={setAutoIncrementHoursThreshold} value={autoIncrementHoursThreshold} />
                                                </div>
                                                <div className="w-full text-gray-dark text-sm"><Translate value="App_Label_Description_Auto_Increment_Hours_Threshold" /></div>
                                            </div>
                                            <div className="flex flex-row justify-center">
                                                <button className="flex flex-row min-w-[4rem] max-w-[7rem] px-2 h-9 rounded-md items-center justify-center space-x-2 text-base text-white bg-primary hover:bg-primary/80 cursor-pointer" onClick={onSave}>
                                                    <span className="select-none"><Translate value='App_Button_Save' /></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>);
}