import { IChangelog } from "../../Models"
import { ChangelogFeature } from "./ChangelogFeature";

interface IChangelogRowProps {
    change: IChangelog
}

export const ChangelogRow = (props: IChangelogRowProps) => {
    const { change } = { ...props };

    return (
        <div className="flex flex-col lg:flex-row w-full">
            <div className="text-xl lg:text-2xl text-primary font-bold flex flex-col items-center lg:pl-4 w-full lg:w-1/2">
                <span>{change.versionNumber}</span>
                <span className="text-base text-primary/40">{change.releaseDate}</span>
            </div>
            <div className="flex flex-col text-base lg:text-xl text-primary w-full lg:w-1/2">
                {change.newFeatures?.map((f, i) => <ChangelogFeature key={i} feature={f} isNew />)}
                {change.bugFixes?.map((f, i) => <ChangelogFeature key={i} feature={f} isBugFix />)}
                {change.deprecatedFeatures?.map((f, i) => <ChangelogFeature key={i} feature={f} isDeprecated />)}
            </div>
        </div>
    )
}