import { translate, Translate } from "react-i18nify";
import { DateTime } from 'luxon'
import { onFormatDate, downloadFile } from '../../Utils/Utils'
import { Dropdown, Field, Label, Option } from "@fluentui/react-components";
import type { DropdownProps, OptionOnSelectData } from "@fluentui/react-components";
import { DayOfWeek } from '@fluentui/react-calendar-compat';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { useEffect, useState, FormEvent } from 'react';
import { useAppDispatch, useAppSelector, useLazyFetchXlsxTimeRecordsQuery, useLazyFetchCsvTimeRecordsQuery, updateLoadingState, useLazyFetchPdfTimeRecordsQuery, useLazyFetchProjectsQuery } from "../../Redux";

const nowDate = DateTime.now();

export const DownloadTimeEntries = () => {
	const dispatch = useAppDispatch();
	const calendarStrings = useAppSelector((state) => state.calendar.calendarStrings);

	const [fromDate, setFromDate] = useState<Date>(nowDate.set({ day: 1 }).toJSDate());
	const [untilDate, setUntilDate] = useState<Date>(nowDate.set({ day: nowDate.daysInMonth }).toJSDate());
	const [dateRange, setDateRange] = useState<Date[]>([fromDate, untilDate]);
	const [projectsOptions, setProjectsOptions] = useState<[]>([]);
	const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
	const [dropdownValue, setDropdownValue] = useState<string>('');
	const [selectedValues, setSelectedValues] = useState<string[]>([]);
	const [disableControls, setDisableControls] = useState<boolean>(false);
	const [validationProps, setValidationProps] = useState<{ validationState: "error" | "warning" | "success" | "none" | undefined, validationMessage: string }>();

	const [fetchProjects, { data: projectsList, isSuccess, isError }] = useLazyFetchProjectsQuery();
	const [triggerGetExcel, { data: excelData, isLoading: waitExcelDownload, isSuccess: isExcelQuerySuccess, isError: isExcelQueryError, error: excelError }] = useLazyFetchXlsxTimeRecordsQuery();
	const [triggerGetCsv, { data: csvData, isLoading: waitCsvDownload, isSuccess: isCsvQuerySuccess, isError: isCsvQueryError }] = useLazyFetchCsvTimeRecordsQuery();
	const [triggerGetPdf, { data: pdfData, isLoading: waitPdfDownload, isSuccess: isPdfQuerySuccess, isError: isPdfQueryError }] = useLazyFetchPdfTimeRecordsQuery();

	useEffect(() => {
		setDateRange([fromDate, untilDate]);
	}, [fromDate, untilDate]);

	useEffect(() => {
		fetchProjects(dateRange);
	}, [dateRange]);

	useEffect(() => {
		if (isExcelQuerySuccess && excelData) {
			dispatch(updateLoadingState(false));
			downloadFile(excelData);
			setDisableControls(false);
		}

		if (isExcelQueryError) {
			dispatch(updateLoadingState(false));
			setDisableControls(false);
		}
	}, [excelData, isExcelQuerySuccess, isExcelQueryError])

	useEffect(() => {
		if (isCsvQuerySuccess && csvData) {
			dispatch(updateLoadingState(false));
			downloadFile(csvData, 'text/csv;encoding:utf-8');
			setDisableControls(false);
		}

		if (isCsvQueryError) {
			dispatch(updateLoadingState(false));
			setDisableControls(false);
		}
	}, [csvData, isCsvQuerySuccess, isCsvQueryError])

	useEffect(() => {
		if (isPdfQuerySuccess && pdfData) {
			dispatch(updateLoadingState(false));
			downloadFile(pdfData);
			setDisableControls(false);
		}

		if (isPdfQueryError) {
			dispatch(updateLoadingState(false));
			setDisableControls(false);
		}
	}, [pdfData, isPdfQuerySuccess, isPdfQueryError])

	useEffect(() => {
		if (waitExcelDownload || waitCsvDownload || waitPdfDownload) {
			dispatch(updateLoadingState(true));
			setDisableControls(true);
		}
	}, [waitExcelDownload, waitCsvDownload, waitPdfDownload])

	const onUntilDateSelected = (date: Date | null | undefined) => {
		if (date && date > fromDate) {
			setUntilDate(date);
		}
	}

	const onDownloadExcelClick = () => {
		dowloadDocument(triggerGetExcel);
	}

	const onDownloadCsvClick = () => {
		dowloadDocument(triggerGetCsv);
	}

	const onDownloadPdfClick = () => {
		dowloadDocument(triggerGetPdf);
	}

	const dowloadDocument = (downloadDocumentFunction: Function) => {
		if (selectedProjects && selectedProjects.length <= 0) {
			setValidationProps({ validationMessage: translate('App_Info_Dropdown_Projects_No_Selection'), validationState: 'error' });
		}
		else {
			setValidationProps({ validationMessage: '', validationState: undefined });
			downloadDocumentFunction({ dateRange: [fromDate, untilDate], projectIds: selectedProjects.filter(key => key !== -1) });
		}
	}

	const onOptionSelect: DropdownProps["onOptionSelect"] = (ev, data: OptionOnSelectData) => {
		setValidationProps({ validationMessage: '', validationState: undefined });
		if (projectsList) {
			const { optionText, optionValue, selectedOptions } = { ...data };
			const selected = selectedOptions.filter(s => s == optionValue).length > 0;
			if (optionValue == '-1') {
				setSelectedProjects(selected ? projectsList.map(p => p.workingPackage.id as number) : []);
				setSelectedValues(selected ? [...projectsList.map(p => `${p.workingPackage.id}`), '-1'] : []);
				setDropdownValue(selected ? projectsList.map(p => p.displayTitle ? p.displayTitle : p.workingPackage.title).join(', ') : '');
			}
			else {
				let currentValues = dropdownValue.length > 0 ? dropdownValue.split(', ') : [];
				const numberValue = Number(optionValue);
				setSelectedProjects(selected ? [...selectedProjects, numberValue] : selectedProjects.filter(t => t !== numberValue));
				setSelectedValues(selectedOptions.filter(key => key !== '-1'));
				setDropdownValue((selected ? [...currentValues, optionText] : currentValues.filter(v => v != optionText)).join(', '));
			}
		}
	};

	return (<>
		<div className="mx-auto w-full h-full lg:w-4/5 lg:shadow-md p-4 mt-5 mb-8">
			<div className="mx-auto flex flex-col w-2/3 space-y-6">
				<div className="w-full">
					<h1 className="font-semibold text-primary text-xl"> <Translate value='App_Info_Download_Time_Entries' /></h1>
				</div>
				<div className="w-full flex flex-row justify-between">
					<label className="text-sm font-semibold text-primary truncate after:text-red after:px-1 after:content-['*'] inline-block w-1/12"><Translate value='App_Forms_Dropdown_Projects' /></label>
					<Field required {...validationProps} className="w-11/12">
						<Dropdown
							id="projects-dropdown"
							placeholder={translate('App_Forms_Dropdown_Projects_Placeholder')}
							multiselect={true}
							appearance="underline"
							value={dropdownValue}
							selectedOptions={selectedValues}
							onOptionSelect={onOptionSelect}
							disabled={disableControls}										
						>
							<Option key={'-1'} value="-1" text={translate('App_Label_Dropdown_Select_All_Projects')}>
								<Translate value='App_Label_Dropdown_Select_All_Projects' />
							</Option>
							{projectsList?.map((option) => (
								<Option key={`${option.workingPackage.id}`} value={`${option.workingPackage.id}`}>
									{option.displayTitle ? option.displayTitle : option.workingPackage.title}
								</Option>
							))}
						</Dropdown>
					</Field>
				</div>
				<div className="w-full flex flex-row justify-between">
					<label className="text-sm font-semibold text-primary truncate after:text-red after:px-1 after:content-['*'] w-1/12"><Translate value='App_Forms_From' /></label>
					<DatePicker isMonthPickerVisible={false}
						placeholder={translate('App_Forms_From')}
						onSelectDate={setFromDate as (date: Date | null | undefined) => void}
						maxDate={untilDate}
						value={fromDate}
						formatDate={onFormatDate}
						firstDayOfWeek={DayOfWeek.Monday}
						strings={calendarStrings}
						disabled={disableControls}
						underlined 
						className="w-11/12"/>
				</div>
				<div className="w-full flex flex-row justify-between">
					<label className="text-sm font-semibold text-primary truncate after:text-red after:px-1 after:content-['*'] w-1/12"><Translate value='App_Forms_Until' /></label>
					<DatePicker isMonthPickerVisible={false}
						placeholder={translate('App_Forms_Until')}
						onSelectDate={onUntilDateSelected}
						minDate={fromDate}
						value={untilDate}
						formatDate={onFormatDate}
						firstDayOfWeek={DayOfWeek.Monday}
						strings={calendarStrings}
						disabled={disableControls}
						underlined 
						className="w-11/12"/>
				</div>
				<div className="w-full flex flex-row justify-around items-center py-8">
					<button className="flex flex-row items-center justify-center space-x-1 text-sm text-primary cursor-pointer hover:bg-primary/10 rounded p-2" onClick={onDownloadExcelClick} disabled={disableControls}>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
							<path fill="#20744a" fillRule="evenodd" d="M28.781 4.405h-10.13V2.018L2 4.588v22.527l16.651 2.868v-3.538h10.13A1.16 1.16 0 0 0 30 25.349V5.5a1.16 1.16 0 0 0-1.219-1.095m.16 21.126H18.617l-.017-1.889h2.487v-2.2h-2.506l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2h10.411Z" />
							<path fill="#20744a" d="M22.487 7.439h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323z" />
							<path fill="#fff" fillRule="evenodd" d="m6.347 10.673l2.146-.123l1.349 3.709l1.594-3.862l2.146-.123l-2.606 5.266l2.606 5.279l-2.269-.153l-1.532-4.024l-1.533 3.871l-2.085-.184l2.422-4.663z" />
						</svg>
						<span><Translate value='App_Button_Download' /> (xlsx)</span>
					</button>
					<button className="flex flex-row items-center justify-center space-x-1 text-sm text-primary cursor-pointer hover:bg-primary/10 rounded p-2" onClick={onDownloadCsvClick} disabled={disableControls}>
						<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
							<path fill="#107c41" d="M30.918 15.983h-.678v-3.271l-.006-.062a.54.54 0 0 0-.132-.358L24.66 6.075l-.004-.004a.6.6 0 0 0-.11-.092l-.036-.022a.6.6 0 0 0-.109-.046l-.03-.01a.5.5 0 0 0-.127-.016H10.867c-.611 0-1.107.497-1.107 1.107v8.99h-.678c-.874 0-1.582.708-1.582 1.582v8.228c0 .873.709 1.582 1.582 1.582h.678v5.632c0 .61.496 1.107 1.107 1.107h18.266c.61 0 1.107-.497 1.107-1.107v-5.632h.678c.873 0 1.582-.708 1.582-1.582v-8.228c0-.873-.708-1.581-1.582-1.581M10.867 6.992H23.69v5.664c0 .306.248.553.554.553h4.89v2.773H10.867zm8.282 15.168c-1.344-.48-2.231-1.224-2.231-2.399c0-1.379 1.164-2.422 3.059-2.422c.924 0 1.583.18 2.063.407l-.408 1.463a3.8 3.8 0 0 0-1.679-.383c-.792 0-1.176.371-1.176.779c0 .516.443.743 1.499 1.139c1.428.528 2.087 1.271 2.087 2.411c0 1.356-1.031 2.507-3.25 2.507c-.924 0-1.835-.252-2.291-.504l.372-1.499a4.6 4.6 0 0 0 2.026.504c.84 0 1.284-.349 1.284-.876c0-.504-.383-.791-1.355-1.127m-9.526-.552c0-2.747 1.967-4.27 4.413-4.27c.948 0 1.667.191 1.991.348l-.384 1.451a3.85 3.85 0 0 0-1.535-.3c-1.439 0-2.566.875-2.566 2.674c0 1.607.959 2.627 2.578 2.627c.564 0 1.164-.107 1.535-.264l.265 1.439c-.324.155-1.092.348-2.063.348c-2.795.001-4.234-1.75-4.234-4.053m19.51 11.1H10.867v-5.333h18.266zm-1.478-7.166H25.52l-2.59-8.084h2.003l.983 3.419c.275.971.527 1.883.719 2.89h.036a36 36 0 0 1 .731-2.854l1.032-3.454h1.942z" />
						</svg>
						<span><Translate value='App_Button_Download' /> (csv)</span>
					</button>
					<button className="flex flex-row items-center justify-center space-x-1 text-sm text-primary cursor-pointer hover:bg-primary/10 rounded p-2" onClick={onDownloadPdfClick} disabled={disableControls}>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
							<path fill="#909090" d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z" />
							<path fill="#f4f4f4" d="M24.031 2H8.808v27.928h20.856V7.873z" />
							<path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5z" />
							<path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077z" />
							<path fill="#464648" d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2 2 0 0 0 .647-.117a1.4 1.4 0 0 0 .493-.291a1.2 1.2 0 0 0 .335-.454a2.1 2.1 0 0 0 .105-.908a2.2 2.2 0 0 0-.114-.644a1.17 1.17 0 0 0-.687-.65a2 2 0 0 0-.409-.104a2 2 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.94.94 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.7 2.7 0 0 0 1.028-.175a1.7 1.7 0 0 0 .68-.491a1.9 1.9 0 0 0 .373-.749a3.7 3.7 0 0 0 .114-.949a4.4 4.4 0 0 0-.087-1.127a1.8 1.8 0 0 0-.4-.733a1.6 1.6 0 0 0-.535-.4a2.4 2.4 0 0 0-.549-.178a1.3 1.3 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.06 1.06 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a3 3 0 0 1-.033.513a1.8 1.8 0 0 1-.169.5a1.1 1.1 0 0 1-.363.36a.67.67 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892" />
							<path fill="#dd2025" d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.5 7.5 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14 14 0 0 0 1.658 2.252a13 13 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.8 10.8 0 0 1-.517 2.434a4.4 4.4 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14 14 0 0 0-2.453.173a12.5 12.5 0 0 1-2.012-2.655a11.8 11.8 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.3 9.3 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.6 9.6 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a23 23 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035" />
							<path fill="#909090" d="M23.954 2.077V7.95h5.633z" />
							<path fill="#f4f4f4" d="M24.031 2v5.873h5.633z" />
							<path fill="#fff" d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2 2 0 0 0 .647-.117a1.4 1.4 0 0 0 .493-.291a1.2 1.2 0 0 0 .332-.454a2.1 2.1 0 0 0 .105-.908a2.2 2.2 0 0 0-.114-.644a1.17 1.17 0 0 0-.687-.65a2 2 0 0 0-.411-.105a2 2 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.94.94 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.7 2.7 0 0 0 1.028-.175a1.7 1.7 0 0 0 .68-.491a1.9 1.9 0 0 0 .373-.749a3.7 3.7 0 0 0 .114-.949a4.4 4.4 0 0 0-.087-1.127a1.8 1.8 0 0 0-.4-.733a1.6 1.6 0 0 0-.535-.4a2.4 2.4 0 0 0-.549-.178a1.3 1.3 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.06 1.06 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a3 3 0 0 1-.033.513a1.8 1.8 0 0 1-.169.5a1.1 1.1 0 0 1-.363.36a.67.67 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892" />
						</svg>
						<span><Translate value='App_Button_Download' /> (pdf)</span>
					</button>
				</div>
			</div>
		</div>
	</>);
} 