import { useEffect, useState } from "react"

export const FunOverlay = () => {
    const [showfunOverlay, setShowFunOverlay] = useState<boolean>(false);

    useEffect(() => {
        if (showfunOverlay) {
            const handleWindowMouseMove = (e: MouseEvent) => {
                let cursor = document.querySelector('#cursor') as HTMLDivElement;
                let background = document.querySelector('#funBackground') as HTMLDivElement;
                cursor.style.top = e.clientY + 'px';
                cursor.style.left = e.clientX + 'px';

                let element = document.createElement('div');
                element.className = 'element'
                background.prepend(element);

                element.style.left = (e.clientX - Math.random() * 100) + 'px';
                element.style.top = (e.clientY - Math.random() * 100) + 'px';

                setTimeout(() => {
                    let text = document.querySelectorAll('.element')[0] as HTMLDivElement,
                        directionX = Math.random() < .5 ? -1 : 1,
                        directionY = Math.random() < .5 ? -1 : 1

                    text.style.top = parseInt(text.style.top) - (directionY * (Math.random() * 200)) + 'px';
                    text.style.left = parseInt(text.style.left) - (directionX * (Math.random() * 200)) + 'px';
                    text.className = "opacity-0 scale-[0.25] pointer-events-none w-2 h-2 absolute transition-all translate-[50%_50%]"
                    text.innerHTML = randomizeText();

                    setTimeout(() => {
                        element.remove();
                    }, 3000)
                }, 20);
            };
            window.addEventListener('mousemove', handleWindowMouseMove);

            return () => {
                window.removeEventListener(
                    'mousemove',
                    handleWindowMouseMove,
                );
            };
        }
    }, [,showfunOverlay]);

    const randomizeText = (): string => {
        var letters = ['metafinanz', 'm', ' ', 'e', ' ', 't', 'technologie', ' ', 'a', ' ', 'f', ' ', 'kultur', 'i', ' ', 'n', ' ', 'a', 'netzwerke', ' ', 'z'];

        return letters[Math.floor(Math.random() * letters.length)];
    }

    window.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.altKey && e.shiftKey && e.code == 'KeyM') {
            setShowFunOverlay(!showfunOverlay)
        }
    });

    return (<>
        {showfunOverlay && <div id="funBackground" className='absolute overflow-hidden bg-neutral/60 w-full min-h-screen z-[1200] top-0 left-0 text-primary text-6xl cursor-none backdrop-blur-sm'>
            <div className="absolute top-4 left-4 text-primary text-2xl">To exit our EasterEgg press <span className="font-bold">Ctrl+Alt+Shift+M</span></div>
            <div id="cursor" className='opacity-0'></div>
            <span className="opacity-0 scale-[0.25] text-primary pointer-events-none w-2 h-2 absolute transition-all text-6xl translate-[50%_50%]"></span>
        </div>}
    </>
    )
}