import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotification } from '../Models/INotification';
import { AppDispatch } from './store';

interface NotificationState {
    notifications: INotification[],
    isCoachmarkVisible: boolean
}

const initialState: NotificationState = {
    notifications: [],
    isCoachmarkVisible: true
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<INotification>) => {
            state.notifications.push(action.payload);
        },
        hideNotification: (state, action: PayloadAction<string>) => {
            state.notifications = state.notifications?.filter(n => n.notificationId != action.payload);
        },
        hideCoachMark: (state) => {
            state.isCoachmarkVisible = false;
        }
    }
});

export function showNotificationWithTimeout(notification: INotification) {
    return function (dispatch: AppDispatch) {
        dispatch(showNotification(notification))

        setTimeout(() => {
            dispatch(hideNotification(notification.notificationId))
        }, 5000)
    }
}

export const { showNotification, hideNotification, hideCoachMark } = notificationSlice.actions;

export default notificationSlice.reducer;