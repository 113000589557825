import { Translate } from "react-i18nify";

const NoProjectsInfoComponent = () => {
    return (<>
        <div className="flex m-auto flex-col space-y-3 text-lg mt-4">
            <div><Translate value="App_Info_No_Project_For_Current_Period" /></div>
            <div><Translate value="App_Info_Contact_Manager_Or" /><a href="mailto:projekt-onboarding@metafinanz.de" className="text-primary font-bold"><Translate value="App_Info_Team" /></a></div>
        </div>
    </>)
}

export default NoProjectsInfoComponent;