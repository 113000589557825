import { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useAppSelector, useAppDispatch, setActivityTemplates, useLazyFetchActivityTemplatesQuery } from '../../Redux';
import { UserIcon } from '@heroicons/react/24/outline'

interface IUserDetailsPersona {
  noDetails?: boolean;
}

export const UserDetailsPersona = (props: IUserDetailsPersona) => {
  const { noDetails } = { ...props }

  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.userProfile.userProfile);
  var isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [initials, setInitials] = useState<string | undefined>('');

  useEffect(() => {
    if (isAuthenticated && accounts.length > 0) {
      var parts = accounts[0].name?.split(' ');
      var initials = parts?.map(p => p[0]).join("");
      if (initials && initials?.length > 2) {
        initials = initials?.slice(0, 2);
      }
      setInitials(initials);
      fetchBookingComments();
    }
  }, [accounts, isAuthenticated]);

  const [fetchBookingComments, { data: bookingComments, isSuccess: isBookingQuerySuccess }] = useLazyFetchActivityTemplatesQuery();

  useEffect(() => {
    if (isBookingQuerySuccess) {
      dispatch(setActivityTemplates(bookingComments));
    }
  }, [isBookingQuerySuccess]);

  return (
    <div className='truncate text-primary flex flex-row justify-start items-center h-10 lg:space-x-2 mx-1 transition-all'>
      <span className='relative flex justify-center items-center p-1 rounded-full ring-1 lg:ring-2 ring-primary w-8 h-8 select-none mx-1'>
        {
          initials && initials.length > 0 ?
            <span className='font-medium lg:font-light text-sm'>{initials}</span>
            : <UserIcon className='w-6 h-6' />
        }
      </span>
      {!noDetails &&
        <span className='hidden lg:relative lg:flex flex-col justify-start text-sm font-light max-w-[15rem]'>
          <span className='truncate transition-all'>{userProfile?.displayName}</span>
          <span className='truncate transition-all'>{userProfile?.email}</span>
        </span>
      }
    </div>
  );
}