import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { useCallback } from "react";
import type { Engine } from "tsparticles-engine";

export const SnowParticles = () => {
    const options = {
        background: {
            color: "transparent",
        },
        particles: {
            number: {
                value: 200,
            },
            color: {
                value: ["#ffffff"]
            },
            fullScreen: false,
            move: {
                enable: true,
                random: false,
                straight: false,
            },
            opacity: {
                value: { min: 0.1, max: 0.8 },
            },
            size: {
                value: { min: 1, max: 8 },
            },
            wobble: {
                distance: 20,
                enable: true,
                speed: {
                    min: -5,
                    max: 10,
                },
            },
        },
    };
    
    const particlesInit = useCallback(async (engine: Engine)=> {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        // await loadFull(engine);
        await loadSlim(engine);
    }, []);

    return ( <Particles init={particlesInit} options={options} />)
}